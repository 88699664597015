import React, { useCallback, useRef } from 'react'
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { Pagination } from "@mui/material";
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import {useMediaQuery} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import { Input } from '@mui/material';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addInvoiceCredit, addPagesInvoices, changeQueueEmail, deleteInvoice, deleteInvoiceQueue, getHistory, getQue, getQueueStatus, removeInvoiceCredit, updateStatusToDelete, updateStatusToDone } from '../../services/Services';
import { pushToProffix, createPdf, sendPdf, loadPdf, generatePdf, loadAddress, updateAddressQueue, loadTemplates, updateTemplate } from '../../services/Services';
import '../queue/queue.scss';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { openAlertSnackBar,closeAlertSnackBar } from "../../shared/common/utilites/notification/snackbar";
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from "@mui/icons-material/Search";
import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import Tab from '@mui/material/Tab';
import { useFormik } from 'formik';  
import * as Yup from "yup";
import PropTypes from 'prop-types';
import CloseIcon from "@mui/icons-material/Close";
import { Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GridFilterPanel } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Loader from '../../shared/common/utilites/PageLoader/PageLoader';
import { createObject, encryptForm } from '../../services/commonService';
import ListIcon from '@mui/icons-material/List';
import HistoryIcon from '@mui/icons-material/History';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
// import { Select, FormControl, InputLabel } from '@mui/material';
import { getEncryptedItem, setEncryptedItem, removeEncryptedItem } from '../../services/localStorageUtil';
import { getCurrency, getLanguages, getConditions, getCountry, getTaxcode, createAddress } from "../../services/Services";
import { CustomModal } from '../../shared/common/utilites/customModal';
import { useTheme } from '@emotion/react';

const CustomDoneIcon = styled(DoneIcon)(({ theme }) => ({
  fontSize: 30 ,
  color: theme.palette.mode === "light" ? '#fff' : '#000'
}))

const CustomMenu = styled(Menu)(({ theme }) => ({
  overflow: 'visible',
  textAlign: 'start',
  // padding: '10px 10px 10px 10px',
  // margin: '0px 10px 0px 10px',
  flex: '0 0 100%',
  flexDirection: 'column',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    justifyContent: 'center',
    // ml: -0.5,
    // mr: 1,
  },
  '& .MuiList-root': {
    borderRadius: '4px',
    // backgroundColor:'#000',
    backgroundColor:theme.palette.mode === "light" ? "#fff" : "#000",
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  borderRadius: "15px",
  bgcolor: "background.paper",
  textAlign: "center",
  backgroundColor: theme.palette.mode === "light" ? "white" : "black",
  border:
    theme.palette.mode === "light"
      ? "1px solid #ccc"
      : "1px solid rgba(255, 255, 255, 0.12)",
}));

const CustomDataGrid = styled(DataGrid)(({ theme }) =>
({
  "& ::-webkit-scrollbar": {
    width: "6px",
    height:"6px"
  },
  "& ::-webkit-scrollbar-track": {
    backgroundColor: "#0000"
  },
  "& ::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
    backgroundColor: "#f5f5f5"
  },
  border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.12)',
  '& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked': {
    color: '#8B9635',
  },
  '&.MuiButtonBase-root .MuiIconButton-root .MuiIconButton-sizeSmall .MuiDataGrid-menuIconButton .css-xfvph6': {
    display: 'none'
  },
  '& .css-cipkih-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate': {
    color: '#8B9635',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'unset'
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'unset'
  },
 '& .MuiDataGrid-row.Mui-selected.commonSelected': {
    backgroundColor: '#8B9635',
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    WebkitTransition: '-webkit-transform .5s,opacity 1.5s,background .5s',
    transition: '-webkit-transform .5s,opacity .5s,background .5s',
    // color: '#000',
    '&.MuiSvgIcon-fontSizeMedium':{
      color:'#000'
    },
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#fff' : '#fff',
      cursor: 'pointer'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: 'unset'
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
}));

const CustomRadio = styled(Radio)(({ theme }) =>({
  '&.Mui-checked': {
    color: '#8B9635',
  },
}))

const CustomDataGridModal = styled(DataGrid)(({ theme }) =>
({
  border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.12)',
  '& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked': {
    color: '#8B9635',
  },
  '& .css-cipkih-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate': {
    color: '#8B9635',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'unset'
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'unset'
  },
  '& .MuiDataGrid-row.Mui-selected.commonSelected': {
    backgroundColor: '#8B9635',
    WebkitTransition: '-webkit-transform .5s,opacity 1.5s,background .5s',
    transition: '-webkit-transform .5s,opacity .5s,background .5s',
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#fff' : '#fff',
      cursor: 'pointer'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
 '& .MuiDataGrid-row .Mui-selected': {
    backgroundColor: '#8B9635',
    WebkitTransition: '-webkit-transform .5s,opacity 1.5s,background .5s',
    transition: '-webkit-transform .5s,opacity .5s,background .5s',
    color: theme.palette.mode === "light" ? "#fff" : "#000",
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#000' : '#fff',
      cursor: 'pointer'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: 'unset'
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
}));
const CustomDataGridItem = styled(DataGrid)(({ theme }) =>
({
  border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid rgba(255, 255, 255, 0.12)',
  '& .css-1bf0mkr-MuiButtonBase-root-MuiCheckbox-root.Mui-checked': {
    color: '#8B9635',
  },
  '& .css-cipkih-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate': {
    color: '#8B9635',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'unset'
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'unset'
  },
  '& .MuiDataGrid-row.Mui-selected': {
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#8B9635' : '#fff',
      cursor: 'pointer'
    },
  },
  '& .MuiDataGrid-row.Mui-selected.commonSelected': {
    backgroundColor: '#8B9635',
    WebkitTransition: '-webkit-transform .5s,opacity 1.5s,background .5s',
    transition: '-webkit-transform .5s,opacity .5s,background .5s',
    color: '#000',
    '& .Mui-checked': {
      color: theme.palette.mode === 'light' ? '#000' : '#fff',
      cursor: 'pointer'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: 'unset',
    display: 'none'
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none",
  },
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.Mui-checked":{
    color:"#8B9635"
  }
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CustomBoxPushtoProffix = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "2%",
  width: 400,
  textAlign: "center",
  backgroundColor: theme.palette.mode === "light" ? "white" : "black",
}));

const CustomBoxAddress = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "2%",
  width: 800,
  textAlign: "center",
  backgroundColor: theme.palette.mode === "light" ? "white" : "black",
}));

const CustomGridPanel = styled(GridFilterPanel)(({ theme }) => ({
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.mode === 'light' ? '#000' : '#fff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.mode === 'light' ? '#000' : '#fff',
  }
}));

function CustomLoadingOverlay() {
  return (<Loader />);
}

const CustomBtn = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '20px',
  background: 'unset',
  border: '2px solid #8B9635',
  padding: "0px 16px 0px 16px",
  width:'100%',
  display:"flex",
  alignItems:"center",
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  '&:hover': {
    backgroundColor: '#8B9635',
    color: theme.palette.mode === "light" ? "#fff" : "#000"
  },
  '&.MuiLoadingButton-root.Mui-disabled': {
    background: theme.palette.mode === 'light' ? '#fff' : '#000',
    padding: '0px 0px',
    span: {
      color: '#8B9635'
    }
  },
}));
const CustomAddPDF = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '20px',
  background: 'unset',
  border: '2px solid #8B9635',
  padding: "0px 16px 0px 16px",
  width:'100%',
  display:"flex",
  alignItems:"center",
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  '&:hover': {
    backgroundColor: '#8B9635',
    color: theme.palette.mode === "light" ? "#fff" : "#000"
  },
  '&.MuiLoadingButton-root.Mui-disabled': {
    background: theme.palette.mode === 'light' ? '#fff' : '#000',
    padding: '0px 0px',
    span: {
      color: '#8B9635'
    }
  },
}));
const CustomFileBox = styled(Box)(({ theme }) => ({
  textTransform: 'none',
  background: 'unset',
  padding: "0px 16px 0px 16px",
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  '&.MuiLoadingButton-root.Mui-disabled': {
    background: theme.palette.mode === 'light' ? '#fff' : '#000',
    padding: '0px 0px',
    span: {
      color: '#8B9635'
    }
  },

  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  border: theme.palette.mode === 'light' ? '1px dashed #000' : '1px dashed #707070',
  height:'50px',
  width :'500px',
  borderRadius:'5px',
  cursor: 'pointer'
}));



const CustomCreateBtn = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '20px',
  background: 'unset',
  border: '2px solid #8B9635',
  padding: '4px 10px',
  margin: '7px 0 7px 0px',
  color: theme.palette.mode === 'light' ? '#000' : '#fff',
  '&:hover': {
    backgroundColor: '#8B9635',
    color: theme.palette.mode === "light" ? "#fff" : "#000"
  },
  '&.MuiLoadingButton-root.Mui-disabled': {
    background: theme.palette.mode === 'light' ? '#fff' : '#000',
    padding: '0px 0px',
    span: {
      color: '#8B9635'
    }
  },
}));


const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .commonqueueTab": {
    textTransform: "none",
    minHeight: '36px',
  },
  '&.MuiTabs-root': {
    minHeight: '0px',
  },
  '& .MuiButtonBase-root.Mui-selected': {
    color: theme.palette.mode === 'light' ? '#000' : '#ffff',
  },
}));


const CustomDiv = styled('div')(({ theme }) => ({
  display: "",
  flex: '0 0 33%',
  borderRadius: '10px',
  border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid #333',
}))


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomNoRowsOverlay() {
  const { t } = useTranslation(["language", "common"]);
  return (
    <GridOverlay>
      <Typography>{t('common_no_data_available')}</Typography>
    </GridOverlay>
  );
}

const defaultQueueParams = { 
  limit: 20, 
  page: 1, 
  column: '', 
  order: '' ,
  filter:'',
  done:'',
  search:''
};

const defaultTemplateParams = { 
  limit: 10, 
  page: 1, 
  column: '', 
  order: '' ,
  search:'',
  active:true};

const defaultAddressParams = { 
  limit: 10, 
  page: 1, 
  column: '', 
  order: '' ,
  search:''};

const defaultSendPdfType ='PDF';

function Queue() {
  const [serviceData, setServiceData] = useState([]);
  const { t, i18n } = useTranslation(["language", "common"]);
  const [tableLoader, setTableLoader] = useState(false);
  const [tableLoaderAddress, setTableLoaderAddress] = useState(false);
  const [tableLoaderTemplate, setTableLoaderTemplate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [createPdfLoading, setCreatePdfLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [isPdf, setIsPdf] = useState(true);
  const [isHistory, setIsHistory] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedItemAddress, setSelectedItemAddress] = useState([]);
  const [selectedItemTemplate, setSelectedItemTemplate] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [iseBill, setIseBill] = useState(false);
  const [selectedRowIdAddress, setSelectedRowIdAddress] = useState([]);
  const [selectedRowIdTemplate, setSelectedRowIdTemplate] = useState([]);
  const [value, setValue] = useState(0);
  const [searchAddress, setSearchAddress] = useState('');
  const [checkAddress, setCheckAddress] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isManualInvoice, setIsManualInvoice] = useState(false);
  const [checkTemplate, setCheckTemplate] = useState(false);
  const [isLoadAddress, setIsLoadAddress] = useState(false);
  const [isPushtoProffix, setIsPushtoProffix] = useState(false);
  const [isCreatePdf, setIsCreatePdf] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isSingleSelection, setIsSingleSelection] = useState(false);
  const [isDirectPayment, setIsDirectPayment] = useState(false);
  const [isUseCredit, setIsUseCredit] = useState(false);
  const [isReopen, setIsReopen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState([]);
  const [addressCount, setAddressCount] = useState(0);
  const [templateCount, setTemplateCount] = useState(0);
  const [pdfData, setPDFData] = useState(null);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [queueCount, setQueueCount] = useState(0);
  const [lastCid, setLastSelectedCid] = useState(null);
  const [isEmailEquals, setIsEmailEquals] = useState(false);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [openPushModal, setOpenPushModal] = useState(false);
 
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [historyData, sethistory] = useState([]);
  const [historyLoader, sethistoryLoader] = useState(false);
  const [languageChange, setlanguageChange] = useState(false);
  const [enableSubmit, setenableSubmit] = useState(true);
  const [enableSubmitTemplate, setenableSubmitTemplate] = useState(true);
  const [showCreateAdrress, setshowCreateAdrress] = useState(false);
  const [country, setCountry] = useState([]);
  const [condition, setConditions] = useState([]);
  const [lang, setLanguage] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [taxCode, settaxCode] = useState([]);
  const [queueParams, setQueueParams] = useState(defaultQueueParams);
  const [searchFields, setSearchFields] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [templateParams, setTemplateParams] = useState(defaultTemplateParams);
  const [addressParams, setAddressParams] = useState(defaultAddressParams);
  const [openStatusDoneModal, setOpenStatusDoneModal] = useState(false);
  const [openStatusDeleteModal, setOpenStatusDeleteModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openFileMergeModal, setOpenFileMergeModal] = useState(false);
  const [openRemoveCreditModal, setOpenRemoveCreditModal] = useState(false);
  const [openAddCreditModal, setOpenAddCreditModal] = useState(false);
  const [sendPdfType, setSendPdfType] = useState(defaultSendPdfType);
  // const [file,setFile]=useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const selectedInvoiceId = useRef([]);
  const currentLanguage = i18n.language;

  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if(selectedRowIds.length>0){
      setAnchorEl(event.currentTarget);
      closeAlertSnackBar();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusDoneModal = () => setOpenStatusDoneModal(true);
  const handleStatusDeleteModal = () => setOpenStatusDeleteModal(true);
  const handleOpenAddressModal = () => setOpenAddressModal(true);
  const handleCloseAddressModal = () => setOpenAddressModal(false);

  const handleOpenPushModal = () => setOpenPushModal(true);
  const handleClosePushModal = () => setOpenPushModal(false);

  const handleOpenTemplateModal = () => setOpenTemplateModal(true);
  const handleCloseTemplateModal = () => setOpenTemplateModal(false);

  const handleCloseStatusDoneModal = () => {
    setOpenStatusDoneModal(false);
  }
  const handleCloseStatusDeleteModal = () => {
    setOpenStatusDeleteModal(false);
  }
 
  useEffect(()=>{
    if(matchDownMD)
    {
      handleClose();
    }
  },[matchDownMD]);

  const handleOpenEmailModal = () => {
    formikEmail.setFieldValue("email_value",selectedRow.email);
    setOpenEmailModal(true)};

  const handleOpenAddPagesModal = () => {
    setOpenFileMergeModal(true)
    // setFile(null);
    formikPDF.resetForm();
  };

  const removeCreditModal = () => {
    console.log(selectedRow);
    setOpenRemoveCreditModal(true)
  };

  const addCreditModal = () => {
    console.log(selectedRow);
    setOpenAddCreditModal(true)
  };

  const handleCloseAddCreditModal = () => {
    setOpenAddCreditModal(false);
  }

  const handleCloseRemoveCreditModal = () => {
    setOpenRemoveCreditModal(false);
  }

  const handleCloseEmailModal = () => {
    setOpenEmailModal(false);
    formikEmail.resetForm();
  }

  const handleCloseFileMergeModal = () => {
    setOpenFileMergeModal(false);
    // setFile(null);
    formikPDF.resetForm();
  }
  const removeFile = () => {
    // setFile(null);
    formikPDF.resetForm();
  }
  
  const handleEmailChange = () => {
    formikEmail.submitForm();
  }
  
  const handleOpenSendModal = () =>{ 
    let addressEmail = selectedRow?.addressemail;
    let invoiceEmail = selectedRow?.email;

    if((invoiceEmail) && (addressEmail)){
      if(invoiceEmail.toLowerCase() ===  addressEmail.toLowerCase()){
        setIsEmailEquals(true);
        formikEmail.setFieldValue("email_value",addressEmail);
      }else{
        setIsEmailEquals(false);
        if(addressEmail)
        {
          formikEmail.setFieldValue("email_value",addressEmail);
        }else{
          formikEmail.setFieldValue("email_value",invoiceEmail);
        }
      }
    }else{
      setIsEmailEquals(false);
      if(invoiceEmail)
      {
        formikEmail.setFieldValue("email_value",invoiceEmail);
      }else{
        formikEmail.setFieldValue("email_value",invoiceEmail);
      }
    }
    setOpenSendModal(true)
  };
  const handleCloseSendModal = () => {
    setOpenSendModal(false);
    setSendPdfType(defaultSendPdfType);
  }

  const resetSelection = ()=>{
    if(queueParams.filter){
      selectedInvoiceId.current =[];
    }
  }
  
  const statusDoneModalContent=()=>{
    return(
      <>
        <p id="child-modal-description">
          {t('confirm_change_status_done')}
        </p>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CustomBtn onClick={handleCloseStatusDoneModal} sx={{ mt: 2 }}>
            {t('common_cancel')}
          </CustomBtn>
          {/* <CustomBtn onClick={changeStatusToDelete} sx={{ mt: 2 ,ml:2,mr:2}}>
              {t('status_to_delete')}
          </CustomBtn>  */}
          <CustomBtn onClick={changeStatusToDone} sx={{ mt: 2 ,ml:2}}>
              {t('status_to_done')}
          </CustomBtn> 
        </div>
      </>
    )
  }

  const statusDelelteModalContent=()=>{
    return(
      <>
        <p id="child-modal-description">
          {t('confirm_delete')}
        </p>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CustomBtn onClick={handleCloseStatusDeleteModal} sx={{ mt: 2 }}>
            {t('common_cancel')}
          </CustomBtn>
          <CustomBtn onClick={deleteInvoiceFromQueue} sx={{ mt: 2 ,ml:2}}>
              {t('common_remove')}
          </CustomBtn> 
        </div>
      </>
    )
  }

  const AddCreditModalContent=()=>{
    return(
      <>
        <p id="child-modal-description">
          {t('confirm_add_credit')}
        </p>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CustomBtn onClick={handleCloseAddCreditModal} sx={{ mt: 2 }}>
            {t('common_cancel')}
          </CustomBtn>
          <CustomBtn onClick={addCredit} sx={{ mt: 2 ,ml:2}}>
              {t('common_add')}
          </CustomBtn> 
        </div>
      </>
    )
  }

  const RemoveCreditModalContent=()=>{
    return(
      <>
        <p id="child-modal-description">
          {t('confirm_remove_credit')}
        </p>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <CustomBtn onClick={handleCloseRemoveCreditModal} sx={{ mt: 2 }}>
            {t('common_cancel')}
          </CustomBtn>
          <CustomBtn onClick={removeCredit} sx={{ mt: 2 ,ml:2}}>
              {t('common_remove')}
          </CustomBtn> 
        </div>
      </>
    )
  }

  // const sendModalContent=()=>{
  //   return(
  //    <>
  //     {(!isEmail)?(<>
  //       <p id="child-modal-description">
  //         {iseBill&&<><b>{t('ebill_confirmation')}</b><br/></>}
  //         {t('confirm_send_reminder') + " "+selectedRow.email+ " ?"}
  //       </p>
  //       <div style={{ display: "flex", justifyContent: "space-between" }}>
  //         <CustomBtn onClick={handleCloseSendModal} sx={{ mt: 2 }}>
  //           {t('common_cancel')}
  //         </CustomBtn>
  //         {(!isReopen)?
  //           <CustomBtn onClick={sendPdfData} sx={{ mt: 2 }}>
  //             { t('common_send')}
  //           </CustomBtn> : 
  //           <CustomBtn onClick={sendIdsReopened} sx={{ mt: 2 }}>
  //             {t('common_send')} 
  //           </CustomBtn>}
  //       </div>
  //      </>):(
  //       <>
  //         <div style={{ textAlign: "start", padding: "0px 25px 20px 25px" }}>
  //               <Typography id="modal-modal-title" variant="h6" component="h2">
  //                 {t('common_update_email')} 
  //               </Typography>
  //                <Grid container sx={{mt:2}}>
  //                   <Grid item xs={12}>
  //                     <TextField
  //                         id="email_value"
  //                         label={t('email')}
  //                         variant="outlined"
  //                         fullWidth
  //                         sx={{ margin: '10px 0 0px 0' }}
  //                         size="small"
  //                         value={formikEmail.values.email_value}
  //                         onChange={formikEmail.handleChange}
  //                         {...formikEmail.getFieldProps("email_value")}
  //                         error={
  //                           formikEmail.touched.email_value &&
  //                           Boolean(formikEmail.errors.email_value)
  //                         }
  //                         helperText={
  //                           formikEmail.touched.email_value && formikEmail.errors.email_value
  //                         }
  //                       />
  //                   </Grid>
  //                   <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
  //                     <Grid item xs={3}>
  //                       <CustomBtn onClick={handleCloseEmailModal} sx={{ mt: 2 }}>
  //                         {t('common_cancel')}
  //                       </CustomBtn>
  //                     </Grid>
  //                     <Grid item xs={5}>
  //                       <CustomBtn  onClick={formikEmail.submitForm} sx={{ mt: 2 }}>
  //                           {t('confirm_email')}
  //                       </CustomBtn> 
  //                     </Grid>
  //                   </Grid>
  //                </Grid>
  //             </div>
  //       </>
  //      )
       
  //     }
  //     </>
  //   )
  // }

  const initialValuesAddress = {
    queue_address: ''
  }

  const formik = useFormik({
    initialValues: initialValuesAddress,
    onSubmit: (values) => {
    }
  })
  const initialValuesEmail = {
    email_value: ''
  }

  const initialValuesPDF = {
    file: null
  }

  const validationSchemaEmail = Yup.object().shape({
    email_value: Yup.string().test(
      'isMultipleEmails',
      t('invalid_email'),
      value => {
        if (!value) return false; 
        const emails = value.split(',').map(email => email.trim());
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        return emails.every(email => emailRegex.test(email));
      }
    )
  });

  const validationSchemaPDF = Yup.object().shape({
    file: Yup.mixed()
      .required('A file is required')
      .test('fileFormat', 'Unsupported Format', value => {
        return value && (value.type === 'application/pdf'); // Only accept PDF files
      }),
  });

  const formikEmail = useFormik({
    initialValues: initialValuesEmail,
    validationSchema:validationSchemaEmail,
    onSubmit: (values) => {
      updateEmail();
    }
  })

  const formikPDF = useFormik({
    initialValues: initialValuesPDF,
    // validationSchema:validationSchemaPDF,
    onSubmit: (values) => {
      console.log(values);
      const file = values.file;
    if (file) {
      console.log('Selected file:', file.name);
      if (file.type === 'application/pdf' || file.name.endsWith('.pdf')) {
        // 
        // setFile(file);
        // console.log("sucess");
        mergePdf(file)
        
      } 
      else {
        handleCloseFileMergeModal();
        return;
      }
    }
      // mergePdf();
    }
  })


  const initialValuesTemplate = {
    queue_template: ''
  }
  const formikTemplate = useFormik({
    initialValues: initialValuesTemplate,
    onSubmit: (values) => {
      editTemplate();
    }
  })

  const itemsColumn = [
    { field: 'lineitemid', headerName: t('item_id'), flex: 0.8, },
    { field: 'description', headerName: t('item_name'), flex: 1.5, },
    { field: 'sellprice', headerName: t('sell_price'), sortable: false, flex: 0.8, },
    { field: 'taxname', headerName: t('tax_code'), sortable: false, flex: 1.8, },
  ];
  const columnAddress = [
    { field: "addressid", headerName: t('common_address_id'), flex: 0.8 },
    { field: "companyname", headerName: t('show_name'), flex: 1.3 },
    { field: "street", headerName: t('common_street'), flex: 1 },
    { field: "housenumber", headerName: t('common_street_number'), flex: 1 },
    { field: "zip", headerName: t('common_zip'), flex: 1 },
    { field: "city", headerName: t('common_city'), flex: 1 },
    { field: "country", headerName: t('common_country_code'), flex: 1 },
  ];

  const columnTemplate = [
    { field: "name", headerName: t('template_description'), flex: 1.5 },
    {
      field: "createddate", headerName: t('template_created_date'), flex: 1.5, valueFormatter:
        currentLanguage === "en"
          ? (params) =>
            params.value !== '' ? new Date(params.value).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
              : params.value

          : (params) =>
            params.value !== '' ? new Date(params.value).toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
              : params.value
    },
    { field: "our_reference", headerName: t('common_our_reference'), flex: 1.5 },
    { field: "language", headerName: t('common_language'), flex: 1 },
    { field: "country", headerName: t('common_country_code'), flex: 1 },
  ];

  const columns = [
    { field: 'invoiceid', headerName: t('invoice_number'), flex: 1,minWidth:100 },
    { field: 'customer', headerName: t('customer'), flex: 1.5,minWidth:100 },
    {
      field: 'invoice_date', headerName: t('invoice_date'), flex: 0.8,minWidth:100,
      valueFormatter:
        currentLanguage === "en"
          ? (params) =>
            params.value !== '' ? new Date(params.value).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
              : params.value
          : (params) =>
            params.value !== '' ? new Date(params.value).toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
              : params.value
    },
    { field: 'total_price', headerName: t('total_price'),minWidth:80, flex: 0.8, sortable: false,valueFormatter:(params) =>params.value ? formattedAmount(parseFloat(params.value)) : params.value},
    {
      field: "guthaben",
      headerName: t('queue_credit'),
      sortable: false,minWidth:100,
      flex: 1.1,
      renderCell: (params) => {
        const {value}=params;
        if(value){
        }
        const isClicked = params.id === lastCid;
        // const iconColor = isClicked ? '#000' : '#8B9635';
        return (
          (value)?(<><CustomDoneIcon sx={{ color: !isClicked && '#8B9635'}} /> <p>({formattedAmount(parseFloat(params.row.creditused))})</p></>) :''
        );
      },
    },
    { field: 'status', headerName: t('status'),minWidth:100, flex: 1.2 ,
      renderCell: (params) => `${params.row.status} ${(params.row.senton)&&(params.row.status.toLowerCase()==='sent')?formateD(params.row.senton):''}
      ${(params.row.iswithoutpushtoproffix)&&(params.row.status.toLowerCase()==='done') ? '(without Proffix)':''}`,
    }
  ];

  const localeText = {
    noRowsLabel: t('common_no_rows'),
    footerRowSelected: (count) => `${count} ${t('common_row_selected')}`,
  };

  const handleKeyDownTemplate = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const Obj = {
        queue_template: event.target.value
      }
      formikTemplate.setValues(Obj);
      setTemplateParams((previousParams)=>({
        ...previousParams,
        search:event.target.value
      }))
    }
  }

  const handleChangeRadioSendPdf=(event)=>
  {
    setSendPdfType(event.target.value);
  }

  const getTemplateModal=()=>
  {
    handleOpenTemplateModal();
    setSelectedItemTemplate([]);
    setenableSubmitTemplate(true);
  }
  const getTemplate = useCallback(() => {
    const controllerGetTemplate = new AbortController();
    setSelectedRowIdTemplate([]);
    setTemplateData([]);
    const signalGetTemplate = controllerGetTemplate.signal;
    const d = new FormData()
    d.append("limit", templateParams.limit);
    d.append("page", templateParams.page);
    d.append("column", templateParams.column);
    d.append("order",  templateParams.order);
    d.append("search", templateParams.search);
    d.append("active", templateParams.active);
    // d.append("isAuthUser", '');
    setTableLoaderTemplate(true);
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    loadTemplates(encrData, signalGetTemplate)
      .then(res => {
        setTableLoaderTemplate(false);
        if (res.status) {
          setTemplateData(res.data);
          setenableSubmitTemplate(true);
          setTemplateCount(Number(res.filtered));
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch(
        (err) => {
          setTableLoaderTemplate(false);
          openAlertSnackBar('error','common_alert','131px')

        }
      );
    return () => {
      controllerGetTemplate.abort();
    }
  }, [templateParams]);

  useEffect(() => {
    if (openTemplateModal) {
      getTemplate();
    }
  }, [getTemplate, openTemplateModal]);

  const getAddress = useCallback(() => {
    const controllerGetAddress = new AbortController();
    const signalLoadAddress = controllerGetAddress.signal;
    const d = new FormData();
    setAddressData([]);
    d.append("limit", addressParams.limit);
    d.append("page", addressParams.page);;
    d.append("column", addressParams.column);
    d.append("order", addressParams.order);
    d.append("search", addressParams.search);    
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    setSelectedRowIdAddress([]);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setTableLoaderAddress(true);
    loadAddress(encrData, signalLoadAddress)
      .then(res => {
        setTableLoaderAddress(false);
        if (res.status) {
          if(res.data.length>0)
          {
            setAddressData(res.data);
            handleOpenAddressModal();
            setAddressCount(Number(res.filtered));
            setenableSubmit(true);
          }else{
            setAddressCount(0);
          }

        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch(
        (err) => {
          setTableLoaderAddress(false);
          openAlertSnackBar('error','common_alert','131px');
        }
      );

    return () => {
      controllerGetAddress.abort();
    }
  }, [addressParams]);

  const handleCheckBoxChange = (event)=>{
    console.log("eve",event.target.checked);
    if(event.target.checked)
    {
      setQueueParams((previousParams)=>({
        ...previousParams,
        done:true,
        page:1
      }))
    }else{
      setQueueParams((previousParams)=>({
        ...previousParams,
        done:"",
        page:1
      }))
    }
  }

  useEffect(() => {
    if (openAddressModal) {
      getAddress();
    }
  }, [getAddress, openAddressModal]);

  const formateD = useCallback((date) => {
    if (currentLanguage === "en") {
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    } else {
      return new Date(date).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    }
  }, [currentLanguage])

  const checkButton = (amount) => {
    setIsReopen(false);
    switch (amount) {
      case 'Invalid address':
        setCheckAddress(true);
        setCheckTemplate(true);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
        setIsOpen(false);
        break;
      case 'Invalid template':
        setCheckTemplate(true);
        setCheckAddress(false);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
        setIsOpen(false);
        break;
      case 'no template no address':
        setCheckAddress(true);
        setCheckTemplate(true);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
        setIsOpen(false);
        break;
      case 'Sent':
        setCheckAddress(false);
        setCheckTemplate(false);
        setIsPdf(true);
        setIsCreatePdf(false);
        setIsPushtoProffix(true);
        setIsSent(false);
        setIsOpen(false);
        break;
      case 'Open':
        setCheckAddress(false);
        setCheckTemplate(true);
        setIsPdf(true);
        setIsCreatePdf(true);
        setIsPushtoProffix(false);
        setIsSent(true);
        setIsReopen(false);
        setIsOpen(true);
        break;
        case 'Done':
          setCheckAddress(false);
          setCheckTemplate(false);
          setIsPdf(true);
          setIsCreatePdf(false);
          setIsPushtoProffix(false);
          setIsSent(false);
          setIsReopen(false);
          setIsOpen(false);
          break;
        case 'Reopen':
        setCheckAddress(false);
        setCheckTemplate(true);
        setIsPdf(true);
        setIsCreatePdf(true);
        setIsPushtoProffix(false);
        setIsSent(false);
        setIsReopen(true);
        setIsOpen(false);
        break;
        case 'Deleted':
        setCheckAddress(false);
        setCheckTemplate(false);
        setIsPdf(true);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
        setIsReopen(false);
        setIsOpen(false);
        break;
      default:
        setCheckAddress(false);
        setCheckTemplate(false);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
        setIsReopen(false);
        setIsOpen(false);
        break;
    }
  }

  const resetIds = () => {
    setSelectedRowIds([])
    setSelectedRow([]);
    setSelectedItem([]);
    setIsHistory(false);
    removeEncryptedItem('selectedRowIds');
    selectedInvoiceId.current =[];
  }

  const showButtons = useCallback((isIds, item, selectedIds, allData) => {
    setCheckAddress(false);
    setCheckTemplate(false);
    setIsPdf(false);
    setIsCreatePdf(false);
    setIsPushtoProffix(false);
    setIsSent(false);
    setIseBill(false);
    setIsReopen(false);
    if (!isIds) {
      if ((item.status === 'Invalid address')) {
        checkButton('Invalid address');
        setSearchAddress(item);
      } else if (item.status === 'Invalid template') {
        checkButton('Invalid template');
      } else if ((item.status === 'no template no address')) {
        checkButton('no template no address');
        setSearchAddress(item);
      }
      else if ((item.status === 'Deleted')) {
        checkButton('Deleted');
      }
      else if ((item.status === 'Open')) {
        checkButton('Open');
        if(item.recipientid)
        {
          setIseBill(true);
        }
      }
      else if ((item.status.startsWith("Sent"))) {
        checkButton('Sent');
      }
      else if ((item.status=== 'Reopen')) {
        checkButton('Reopen');
        if(item.recipientid)
        {
          setIseBill(true);
        }
      }
      else if ((item.status=== 'Done')) {
        checkButton('Done');
        if(item.recipientid)
        {
          setIseBill(true);
        }
      }
    } else {
      setIsOpen(false);
      let isOpenCondition = false;
      let isSentCondition = false;
      let isDeletedCondition = false;
      let isTemplateCondition = false;
      let isAddressCondition = false;
      let isreopenCondition = false;
      let isdoneCondition = false;
      let lastValue = selectedIds[selectedIds.length - 1];

      const openIds = allData.filter(value => value.status === 'Open').map(value => value.cid);
      const sentIds = allData.filter(value => value.status.startsWith("Sent")).map(value => value.cid);
      const invalidTemplateIds = allData.filter(value => value.status === 'Invalid template').map(value => value.cid);
      const invalidAddressIds = allData.filter(value => value.status === 'Invalid address').map(value => value.cid);
      const reopenIds = allData.filter(value => value.status === 'Reopen').map(value => value.cid);
      const doneIds = allData.filter(value => value.status === 'Done').map(value => value.cid);
      const deletedIds = allData.filter(value => value.status === 'Deleted').map(value => value.cid);
      const lastValueItem = allData.filter(value => value.cid === lastValue);

      if (openIds.length > 0) {
        isOpenCondition = openIds.some(val => selectedIds.includes(val));
        const seletedOpenItems = allData.filter(value =>openIds.includes(value.cid) && selectedIds.includes(value.cid));
        const isEbill =seletedOpenItems.some(val=>val.recipientid);
        setIseBill(isEbill);
      }

      if (sentIds.length > 0) {
        isSentCondition = sentIds.some(val => selectedIds.includes(val));
      }
      if (deletedIds.length > 0) {
        isDeletedCondition = deletedIds.some(val => selectedIds.includes(val));
      }
      if (invalidTemplateIds.length > 0) {
        isTemplateCondition = invalidTemplateIds.some(val => selectedIds.includes(val));
      }
      if (invalidAddressIds.length > 0) {
        isAddressCondition = invalidAddressIds.some(val => selectedIds.includes(val));
      }
      if (reopenIds.length > 0) {
        isreopenCondition = reopenIds.some(val => selectedIds.includes(val));
        const seletedOpenItems = allData.filter(value =>reopenIds.includes(value.cid) && selectedIds.includes(value.cid));
        const isEbill =seletedOpenItems.some(val=>val.recipientid);
        setIseBill(isEbill);
      }
      if (doneIds.length > 0) {
        isdoneCondition = doneIds.some(val => selectedIds.includes(val));
        const seletedOpenItems = allData.filter(value =>doneIds.includes(value.cid) && selectedIds.includes(value.cid));
        const isEbill =seletedOpenItems.some(val=>val.recipientid);
        setIseBill(isEbill);
      }
      
      if (isDeletedCondition) {
        setCheckAddress(false);
        setCheckTemplate(false);
        setIsPdf(true);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
        setIsReopen(false);
        return
      }
      if (isAddressCondition) {
        setCheckAddress(true);
        setCheckTemplate(true);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
      }
      if (isTemplateCondition) {
        setCheckTemplate(true);
        setCheckAddress(false);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
      }
      if ((isOpenCondition) || ((isOpenCondition) && ((isTemplateCondition) || (isAddressCondition)))) {
        setCheckTemplate(true);
        setIsCreatePdf(true);
        setIsPushtoProffix(false);
        setIsSent(true);
      }
      if ((isSentCondition)) {
        // setIsPdf(true);
        setIsCreatePdf(false);
        setIsPushtoProffix(true);
        setIsSent(false);
      }
      if ((isreopenCondition)) {
        // setIsPdf(true);
        setIsPdf(true);
        setIsCreatePdf(true);
        setIsReopen(true);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(true);
      }
      if ((isdoneCondition)) {
        // setIsPdf(true);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      if ( (isreopenCondition)) {
        // setIsPdf(true);
        setIsCreatePdf(false);
        setIsReopen(true);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      if( (isdoneCondition) &&(isAddressCondition) ) {
        setCheckAddress(true);
        setCheckTemplate(true);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
        setIsReopen(false);
      }
      if( (isdoneCondition) && (isOpenCondition) ) {
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      
      if ((isdoneCondition) && (isTemplateCondition)) {
        // setIsPdf(true);
        setCheckAddress(false);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(true);
        }
      if ((isSentCondition) && ((isTemplateCondition) || (isAddressCondition))) {
        // setIsPdf(true);
        setIsCreatePdf(false);
        setIsPushtoProffix(false);
        setIsSent(false);
      }
      if (((isSentCondition) && (isOpenCondition)) && (((isSentCondition) && (isOpenCondition)) || (isTemplateCondition) || (isAddressCondition))) {
        setIsSent(true);
        setIsCreatePdf(true);
        setIsPushtoProffix(false);
      }
      if (((isSentCondition) && (isOpenCondition)) &&  (isTemplateCondition) && (isAddressCondition) && (isdoneCondition)) {
        // setIsPdf(true);
        setCheckAddress(false);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      
      if ((isSentCondition) && (isdoneCondition) && (isreopenCondition) ) {
        // setIsPdf(true);
        setCheckAddress(false);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      if ((isSentCondition) && (isOpenCondition) && (isdoneCondition)) {
        // setIsPdf(true);
        setCheckAddress(false);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      if ((isSentCondition) && (isOpenCondition) && (isAddressCondition) && (isdoneCondition)) {
        // setIsPdf(true);
        setCheckAddress(false);
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      
      if  ( (isSentCondition || isAddressCondition || isreopenCondition || (isOpenCondition && isdoneCondition)) && lastValueItem[0].status === 'Invalid template' ) {
        // setIsPdf(true);
        setCheckAddress(false);
        setIsPdf(false);
        setIsReopen(false);
        setIsPushtoProffix(false);
        setCheckTemplate(true);
           if(isOpenCondition)
           {
            if(isdoneCondition)
            {
              setIsCreatePdf(false);
              setIsSent(false);
            }
            else{
            setIsCreatePdf(true);
            setIsSent(true);
            }
           } 
           else{
            setIsCreatePdf(false);
            setIsSent(false);
           }
      }
      
      if ((lastValueItem[0].status === 'Open') || (lastValueItem[0].status.startsWith("Sent"))) {
        (isdoneCondition)?setIsPdf(false):setIsPdf(true);
        // setIsSent(true);
        setIsReopen(false)
        if (lastValueItem[0].status === 'Open') {
          setIsOpen(true);
        }
        if ((lastValueItem[0].status.startsWith("Sent"))) {
          setCheckTemplate(false);
          setCheckAddress(false);
          setIsSent(false);
        } else {
          (isreopenCondition)?setIsSent(false):setIsSent(true);
          (isdoneCondition)?setIsSent(false):setIsSent(true);
          (isdoneCondition)?setIsCreatePdf(false):setIsCreatePdf(true);
          (isdoneCondition)?setCheckTemplate(false):setCheckTemplate(true);
          setIsPushtoProffix(false);
          setCheckAddress(false);
        }
      }
      else if (lastValueItem[0].status === 'Invalid address') {
        setCheckAddress(true);
        setCheckTemplate(true);
        setIsPdf(false);
        setIsCreatePdf(false);
        setSearchAddress(lastValueItem[0]);
        setIsPushtoProffix(false);
        setIsReopen(false);
        if(isOpenCondition)
        {
          // setIsCreatePdf(true);
          // setIsPdf(true);
          setIsSent(true);
        }
        if((isdoneCondition))
        {
          setIsCreatePdf(false);
          setIsPdf(false);
          setIsSent(false)
        }
      }
      else if ((isOpenCondition === 'true') && (lastValueItem[0].status === 'Done')) {
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      else if ((isdoneCondition.length > 0) && lastValueItem[0].status === 'Done')  {
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      else if ((isdoneCondition.length > 0) && (isAddressCondition.length > 0)  && lastValueItem[0].status === 'Invalid template')  {
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(true);
      }
      else if ((isreopenCondition.length > 0) && lastValueItem[0].status === 'Done')  {
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsReopen(false);
        setIsSent(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      else if ((isOpenCondition.length > 0) && lastValueItem[0].status === 'Invalid template')  {
        setIsPdf(false);
        setIsCreatePdf(true);
        setIsReopen(true);
        setIsSent(true);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
      }
      else if ((isOpenCondition.length > 0) && lastValueItem[0].status === 'Invalid address')  {
        setIsPdf(false);
        setIsReopen(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
        if(isdoneCondition)
        {
          setIsCreatePdf(false);
           setIsSent(true);
        }
        else{
          setIsCreatePdf(true);
          setIsSent(true);
        }
      }
      else if (lastValueItem[0].status === 'Done')  {
   
         setIsPdf(true);
        if((isreopenCondition)){
          setIsReopen(false);
        }
        else{
          setIsReopen(false);
        }
        if ((isSentCondition || isOpenCondition || isTemplateCondition || isAddressCondition) && lastValueItem[0].status === 'Done') {
          setIsReopen(false);
        }
        else{
          // setIsReopen(true);
        }
         setIsPushtoProffix(false);
         setCheckTemplate(false);
         setCheckAddress(false);
      }
      else if ( (isdoneCondition.length > 0) && lastValueItem[0].status === 'Reopen') {
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsSent(false);
        setIsReopen(true);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
        setCheckAddress(false);
      }
      else if ( (isdoneCondition.length > 0) && (isOpenCondition.length > 0) &&(isreopenCondition.length > 0) ){
        setIsPdf(false);
        setIsCreatePdf(false);
        setIsSent(false);
        setIsReopen(false);
        setIsPushtoProffix(false);
        setCheckTemplate(false);
        setCheckAddress(false);
      }

      else if (lastValueItem[0].status === 'Reopen') {
      
        if(isdoneCondition)
        {
          setIsPdf(true);
          setIsCreatePdf(false);
          setIsReopen(false)
          setCheckTemplate(false)
        }
        if ((isSentCondition || isOpenCondition || isTemplateCondition || isAddressCondition) && isdoneCondition ) {
          setIsReopen(false);
          setIsPushtoProffix(false);
          setCheckAddress(false);
          setIsPdf(false);
          setIsCreatePdf(false);
          setCheckTemplate(false);
        }
    }
      // else if (lastValueItem[0].status === 'Done') {
      //   setIsPdf(false);
      //   setIsCreatePdf(false);
      //   (isOpenCondition)?setIsReopen(false):setIsReopen(false);
      //   setIsSent(false);
      //   setIsPushtoProffix(false);
      //   setCheckTemplate(false);
      //   setCheckAddress(false);
      //   setIsReopen(true);
      // }
    }
  }, [])

  const formatDate = useCallback((data) => {
    if (currentLanguage === "en") {
      return new Date(data).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    } else {
      return new Date(data).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    }
  }, [currentLanguage])

  const handlePageChange = (event, newPage) => {
    setQueueParams((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
    removeEncryptedItem("selectedRowIds");
    selectedInvoiceId.current =[];
  };

  const addressHandlePageChange = (event, newPage) => {
    setAddressParams((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
  };

  const templateHandlePageChange = (event, newPage) => {
    setTemplateParams((previousParams)=>({
      ...previousParams,
      page:event + 1
    }))
  };

  const handleRowClick = (params) => {
    setSelection(params);
  };
  const formattedAmount = (amount)=>{
    return amount.toLocaleString('de-CH', { 
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
  });
 }
  const handleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setQueueParams((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setQueueParams((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };
  
  const templatehandleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setTemplateParams((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setTemplateParams((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };

  const addressHandleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setAddressParams((previousParams)=>({
        ...previousParams,
        column:newModel[0].field,
        order:newModel[0].sort,
        page:1
      }))
    }else{
      setAddressParams((previousParams)=>({
        ...previousParams,
        column:"",
        order:"",
        page:1
      }))
    }
  };

  const handleSelectionModelChange = (newSelection) => {
    if (newSelection.length !== 0) {
      setEncryptedItem('selectedRowIds', JSON.stringify(newSelection));
    } else {
      !languageChange && removeEncryptedItem('selectedRowIds');
    }
    setPDFData(null);
    setLastSelectedCid(null);
    setSelectedRowIds(newSelection);
    setCheckAddress(false);
    setCheckTemplate(false);
    setIsPdf(false);
    setIsCreatePdf(false);
    setIsSent(false);
    setValue(0);
    if (newSelection.length === 0) return (setSelectedRowIds([]), setSelectedRow([]), setSelectedItem([]), setIsHistory(false),checkButton('reset'));
    const filteredObject = serviceData.filter(
      (obj) => obj.cid === newSelection[newSelection.length - 1]
    );
    selectedInvoiceId.current =[];
    if (filteredObject.length === 0) return (setSelectedRow([]), setSelectedItem([]), setIsHistory(false));

    if (newSelection.length === 1) {
      showButtons(false, filteredObject[0], newSelection, serviceData);
    } else if (newSelection.length > 1) {
      showButtons(true, filteredObject[0], newSelection, serviceData);
    }
    
    setLastSelectedCid(filteredObject[0].cid);
    setIsHistory(true);
    setSelectedItem(filteredObject[0]['items']);
    filteredObject.forEach((it) => {
      const objectWithoutExcludedKey = Object.keys(it).reduce(
        (result, key) => {
          if (key === "cid") {
            const { [key]: value, ...rest } = it;
            result = { ...result, ...rest };
          }
          return result;
        },
        {}
      );
      setSelectedRow(objectWithoutExcludedKey);
    });
  };

  const handleSelectionModelChangeAddress = (newSelection) => {
    setSelectedRowIdAddress(newSelection);
    const filteredObject = addressData.filter(
      (obj) => obj.cid === newSelection[newSelection.length - 1]
    );
    if(newSelection.length>0)
    {
      setenableSubmit(false);
    }else{
      setenableSubmit(true);
    }
    if (filteredObject.length === 0) return (setSelectedRowIdAddress([]));
    setSelectedItemAddress(filteredObject[0])
  };

  const handleSelectionModelChangeTemplate = (newSelection) => {
    if(newSelection.length === 0){setSelectedRowIdTemplate([]) ; return}
    setSelectedRowIdTemplate(newSelection);
    const filteredObject = templateData.filter(
      (obj) => obj.cid === newSelection[newSelection.length - 1]
    );
    if (filteredObject.length === 0) return (setSelectedRowIdTemplate([]));
    if(filteredObject.length>0)
    {
      setenableSubmitTemplate(false);
    }
    setSelectedItemTemplate(filteredObject[0])
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 3) {
      getPdfData();
    } else {
      setPDFData(null);
    }
    if (newValue === 2) {
      loadHistory()
    }
  };

  const handlePageSizeChange = (event) => {
    setQueueParams((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };

  const handleCloseAddress = () => {
    handleCloseAddressModal();
    formik.resetForm();
    formikAddress.resetForm();
    setshowCreateAdrress(false);
    const Obj = {
      queue_address: searchAddress
    }
    formik.setValues(Obj);
    formikAddress.resetForm();
    setshowCreateAdrress(false);
  };

  const handleCloseTemplate = () => {
    handleCloseTemplateModal();
    setSelectedItemTemplate([]);
    const Obj = {
      queue_template: ""
    }
    formikTemplate.setValues(Obj);
    setTemplateParams((previousParams)=>({
      ...previousParams,
      search:""
    }))
  };

  const sendSelectedIdsToPushQueue = () => {
    handleClosePushModal();
    setLoading(true);
    const _formData = new FormData()
    _formData.append("message", JSON.stringify(selectedRowIds));
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    pushToProffix(encrData)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          resetIds();
          openAlertSnackBar('success',res.message,'131px');
          loadQueue(true);
          checkButton('reset');
          handleClose();
        }
        else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch((err) => {
        setLoading(false);
        openAlertSnackBar('error','common_alert','131px')
      });
  };

  const setSelection = useCallback((params = '') => {
    if (params !== '') {
      serviceData.forEach((it, index) => {
        if (it.cid === params.id) {
          const objectWithoutExcludedKey = Object.keys(it).reduce((result, key) => {
            if (key === "cid") {
              const { [key]: value, ...rest } = it;
              result = { ...result, ...rest };
            }
            return result;
          }, {});
          setSelectedRow(objectWithoutExcludedKey);
          setSelectedItem(it['items']);
        }
      });
    }
  }, [serviceData]);

  const loadQueue = useCallback((isMounted) => {
    const d = new FormData()
    d.append("limit", queueParams.limit);
    d.append("page", queueParams.page);
    d.append("column", queueParams.column);
    d.append("order", queueParams.order);
    d.append("search", queueParams.search);
    d.append("filter", queueParams.filter);
    d.append("done", queueParams.done);
     //d.append("isAuthUser", '');

    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setServiceData([]);
    setLastSelectedCid(null);
    setTableLoader(true);
    setIsLoading(false);
    setIsPdf(false);
    getQue(encrData)
      .then((res) => {
        if (isMounted) {
          setTableLoader(false);
          if (res.status) {
            if (res.data.length !== 0) {
              let editArray = res.data.map((item) => {
                if ((item.senton) && (!isNaN(new Date(item.senton))) && (item.status === 'Sent')) {
                  return {
                    ...item
                  }
                }
                return item;
              })
              setServiceData(editArray);
              setlanguageChange(false);
              let storedSelection;
              if(selectedInvoiceId.current.length>0){
                storedSelection = selectedInvoiceId.current;
              }
              let isRowIds = false;
              if (storedSelection) {
                setSelectedRowIds(storedSelection);
                isRowIds = true;
                setIsHistory(true);
                const lastRow = storedSelection[storedSelection.length - 1];
                setLastSelectedCid(lastRow);
                const lastItem = res.data.filter((items) => items.cid === lastRow);
                if(lastItem.length > 0)
                {
                  setSelectedItem(lastItem[0]['items']);
                  setSelectedRow(lastItem[0]);
                }else{
                  storedSelection = [];
                  setSelectedRowIds([]);
                  isRowIds = false;
                }
                if(storedSelection.length>0){setSelectedRowIds(selectedInvoiceId.current);}
              }
              (isRowIds) && showButtons(isRowIds, res.data[0], storedSelection, res.data);
              Number(res.filtered) !== 0 ? setQueueCount(Number(res.filtered)) : setQueueCount(0);
            }
            else {
              setQueueCount(0);
            }
            setValue(0)
          }
          else {
          openAlertSnackBar('error',res.message,'131px');
            setSelectedItem([]);
            setSelectedRow([]);
          }
        }
      }).catch((err) => {
        setTableLoader(false);
        setSelectedItem([]);
        setSelectedRow([]);
       openAlertSnackBar('error','common_alert','131px')
      });
  }, [queueParams, showButtons]);


  useEffect(() => {
    let userData = getEncryptedItem('userData');
      if(userData)
      {
          let userDataobj = JSON.parse(userData)
          setUserDetails(userDataobj);
      }
    let isMounted = true;
    loadQueue(isMounted);
    return () => {
      isMounted = false;
      setServiceData([]);
      closeAlertSnackBar();
    };
  }, [loadQueue]);

  useEffect(() => {
    try {
      const controller1 = new AbortController();
      const signal1 = controller1.signal;
      
      getQueueStatus(signal1)
        .then(data => {
          if((data)&&(data.status)&&(data.data)&&(data.data.length>0))
          {
            setStatus(data.data);
          }
        });
      return () => {
        controller1.abort();
      };
    } catch (error) {
    }
  }, []);

  useEffect(() => {
    if (selectedRowIds.length ===1) {
      if(!selectedRow.status)return;

      if(selectedRow.isdirectpayment){
        setIsDirectPayment(true);
      }else{
        setIsDirectPayment(false);
      }

      if(selectedRow?.iscreditinModule){
        setIsUseCredit(true);
      }else{
        setIsUseCredit(false);
      }

      if((selectedRow.status.toLowerCase()==="done")||(selectedRow.status.toLowerCase()==="sent")){
        setIsSingleSelection(false);
      }else{
        setIsSingleSelection(true);
      }

      if(selectedRow.status.toLowerCase()==="done"){
        setIsDone(false);
      }else{
        setIsDone(true);
      }

      if(selectedRow.status.toLowerCase()==="open"){
        if(selectedRow?.ismanualinvoice){
          setIsManualInvoice(true);
        }else{
          setIsManualInvoice(false);
        }
      }else{
        setIsManualInvoice(false);
      }
    }else{
      setIsSingleSelection(false);
      setIsDone(false);
      setIsManualInvoice(false);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      removeEncryptedItem("selectedRowIds");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      removeEncryptedItem('selectedRowIds');
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setlanguageChange(true);
  }, [currentLanguage]);


  const editAddress = useCallback(() => {
    const controllerEditAddress = new AbortController();
    const signalEditAddress = controllerEditAddress.signal;
    selectedInvoiceId.current =[lastCid];
    const d = new FormData()
    d.append("addressId", selectedItemAddress?.addressid);
    d.append("queueId", lastCid);
    d.append("iolibUid", selectedRow?.iolibuid);
    d.append("userName", userDetails?.username);
    // d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    handleCloseAddressModal();
    formik.resetForm();
    updateAddressQueue(encrData, signalEditAddress)
      .then(res => {
        if (res.status) {
          resetSelection();
          loadQueue(true);
          openAlertSnackBar('success',t(res.message),'131px');
          handleClose();
        } else {
          openAlertSnackBar('error',t(res.message),'131px');
        }
      }).catch(
        (err) => {
          openAlertSnackBar('error','common_alert','131px')
        }
      );

    return () => {
      controllerEditAddress.abort();
    }
  }, [selectedRow,selectedItemAddress, lastCid, loadQueue, formik, userDetails]);

  const mergePdf = useCallback((file) => {

    if(!file){return}
    const controllerAddPDF = new AbortController();
    const signalAddPDF = controllerAddPDF.signal;
   
    const d = new FormData();
    d.append("queueId", lastCid);
    // d.append("file", file);
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    encrData.append("file", file);
    addPagesInvoices(encrData, signalAddPDF)
      .then(res => {
        if (res.status) {
          loadQueue(true);
          openAlertSnackBar('success',t(res.message),'131px');
          handleCloseFileMergeModal();
        } else {
          openAlertSnackBar('error',t(res.message),'131px');
        }
      }).catch(
        (err) => {
          openAlertSnackBar('error','common_alert','131px')
        }
      );

    return () => {
      controllerAddPDF.abort();
    }
  }, [lastCid]);

  useEffect(()=>{
    console.log("lastCid",lastCid);
    
  },[lastCid]);

  const editTemplate = useCallback(() => {
    const controllerEditTemplate = new AbortController();
    const signalEditTemplate = controllerEditTemplate.signal;
    selectedInvoiceId.current = [lastCid];
    const d = new FormData()
    d.append("queueId", lastCid);
    d.append("templateId", selectedItemTemplate?.cid );
    d.append("userName", userDetails?.username);
    // d.append("isAuthUser", '');
    let object = createObject(d);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    handleCloseTemplateModal();
    formikTemplate.resetForm();
    updateTemplate(encrData, signalEditTemplate)
      .then(res => {
        if (res.status) {
          resetSelection();
          loadQueue(true);
          openAlertSnackBar('success',res.message,'131px');
          handleClose();
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch(
        (err) => {
          openAlertSnackBar('error','common_alert','131px')
        }
      );

    return () => {
      controllerEditTemplate.abort();
    }
  }, [selectedItemTemplate, lastCid, loadQueue, formikTemplate, userDetails]);

  const lastSelectedRowIndex = selectedRowIds.length > 0 ? selectedRowIds[selectedRowIds.length - 1] : null;
  const getRowClassName = useCallback((params) => {
    if (lastSelectedRowIndex !== null && params.id === lastSelectedRowIndex) {
      return 'commonSelected';
    }
    if (lastSelectedRowIndex === null) {
      return 'commonSelected';
    }
    return '';

  }, [lastSelectedRowIndex]);

  const lastSelectedRowIndexAddress = selectedRowIdAddress.length > 0 ? selectedRowIdAddress[selectedRowIdAddress.length - 1] : null;
  const getRowClassNameAddress = useCallback((params) => {
    if (lastSelectedRowIndexAddress !== null && params.id === lastSelectedRowIndexAddress) {
      return 'commonSelected';
    }
    if (lastSelectedRowIndexAddress === null) {
      return 'commonSelected';
    }
    return '';

  }, [lastSelectedRowIndexAddress]);

  const lastSelectedRowIndexTemplate = selectedRowIdTemplate.length > 0 ? selectedRowIdTemplate[selectedRowIdTemplate.length - 1] : null;
  const getRowClassNameTemplate = useCallback((params) => {
    if (lastSelectedRowIndexTemplate !== null && params.id === lastSelectedRowIndexTemplate) {
      return 'commonSelected';
    }
    if (lastSelectedRowIndexTemplate === null) {
      return 'commonSelected';
    }
    return '';

  }, [lastSelectedRowIndexTemplate]);

  const addressHandlePageSizeChange = (event) => {
    setAddressParams((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };
  const templateHandlePageSizeChange = (event) => {
    setTemplateParams((previousParams)=>({
      ...previousParams,
      limit:event,
      page:1
    }))
  };

  const createPdfData = () => {
    setCreatePdfLoading(true);
    const _formData = new FormData()
    _formData.append("id", JSON.stringify(selectedRowIds));
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    createPdf(encrData)
      .then(res => {
        setCreatePdfLoading(false);
        if (res.status) {
          handleClose();
          openAlertSnackBar('success',res.message,'131px');
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch((err) => {
        setCreatePdfLoading(false);
        openAlertSnackBar('error','common_alert','131px')
      });

  };

  const changeStatusToDone = () => {
    if(selectedRowIds.length > 1){return}
    handleCloseStatusDoneModal();
    const _formData = new FormData()
    _formData.append("cid", JSON.stringify(selectedRowIds[0]));
    _formData.append("oldstatus",selectedRow.status );
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    updateStatusToDone(encrData)
      .then(res => {
        if (res.status) {
          loadQueue(true);
          openAlertSnackBar('success',res.message,'131px');
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch((err) => {
        openAlertSnackBar('error','common_alert','131px')
      });
  }

  const changeStatusToDelete = () => {
    if(selectedRowIds.length > 1){return}
    handleCloseStatusDoneModal();
    const _formData = new FormData()
    _formData.append("cid", JSON.stringify(selectedRowIds[0]));
    _formData.append("oldstatus",selectedRow.status );
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    updateStatusToDelete(encrData)
      .then(res => {
        if (res.status) {
          loadQueue(true);
          openAlertSnackBar('success',res.message,'131px');
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch((err) => {
        openAlertSnackBar('error','common_alert','131px')
      });
  }

  const deleteInvoiceFromQueue = () => {
    if(selectedRowIds.length > 1){return}
    handleCloseStatusDeleteModal();
    const _formData = new FormData()
    _formData.append("cid", JSON.stringify(selectedRowIds[0]));
    _formData.append("oldstatus",selectedRow.status );
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    deleteInvoiceQueue(encrData)
      .then(res => {
        if (res.status) {
          loadQueue(true);
          openAlertSnackBar('success',res.message,'131px');
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch((err) => {
        openAlertSnackBar('error','common_alert','131px')
      });
  }

  const removeCredit = () => {
    if(selectedRowIds.length > 1){return}
    handleCloseRemoveCreditModal();
    const _formData = new FormData()
    _formData.append("cid", JSON.stringify(selectedRowIds[0]));
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    removeInvoiceCredit(encrData)
      .then(res => {
        if (res.status) {
          loadQueue(true);
          openAlertSnackBar('success',res.message,'131px');
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch((err) => {
        openAlertSnackBar('error','common_alert','131px')
      });
  }

  const addCredit = () => {
    if(selectedRowIds.length > 1){return}
    handleCloseAddCreditModal();
    const _formData = new FormData()
    _formData.append("cid", JSON.stringify(selectedRowIds[0]));
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    addInvoiceCredit(encrData)
      .then(res => {
        if (res.status) {
          loadQueue(true);
          openAlertSnackBar('success',res.message,'131px');
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch((err) => {
        openAlertSnackBar('error','common_alert','131px')
      });
  }
  
  const updateEmail = () => {
    handleCloseEmailModal();
    const _formData = new FormData();
    selectedInvoiceId.current = selectedRowIds ;
    _formData.append("cid", JSON.stringify(selectedRowIds[0]));
    _formData.append("email", formikEmail.values.email_value);
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setSendLoading(true);
    changeQueueEmail(encrData)
    .then(res => {
      setSendLoading(false);
      if (res.status) {
        loadQueue(true);
        formikEmail.resetForm();
        handleClose();
      } else {
        openAlertSnackBar('error',res.message,'131px');
      }
    }).catch((err) => {
      setSendLoading(false);
     openAlertSnackBar('error','common_alert','131px')
    });
  }

  const updateEmailAndSend = () => {
    handleCloseEmailModal();
    const _formData = new FormData();
    selectedInvoiceId.current = selectedRowIds ;
    _formData.append("cid", JSON.stringify(selectedRowIds[0]));
    _formData.append("email", formikEmail.values.email_value);
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setSendLoading(true);
    changeQueueEmail(encrData)
    .then(res => {
      setSendLoading(false);
      if (res.status) {
        sendPdfData();
        formikEmail.resetForm();
      } else {
        openAlertSnackBar('error',res.message,'131px');
      }
    }).catch((err) => {
      setSendLoading(false);
     openAlertSnackBar('error','common_alert','131px')
    });
  }

  const sendPdfData = () => {
    handleCloseSendModal();
    const _formData = new FormData()
    _formData.append("id", JSON.stringify(selectedRowIds));
    _formData.append("sendType", 'queue');
    _formData.append("type", sendPdfType ? sendPdfType:'');
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setSendLoading(true);
    sendPdf(encrData)
      .then(res => {
        setSendLoading(false);
        if (res.status) {
          loadQueue(true);
          if(!isReopen){
            sendSelectedIdsToPushQueue();
          }
          handleClose();
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch((err) => {
        setSendLoading(false);
        openAlertSnackBar('error','common_alert','131px')
      });
  }

  const sendIdsReopened = () => {
    handleCloseSendModal();
    const _formData = new FormData()
    _formData.append("id", JSON.stringify(selectedRowIds));
    _formData.append("sendType", 'queue');
    _formData.append("userName", userDetails?.username);
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    setSendLoading(true);
    sendPdf(encrData)
      .then(res => {
        setSendLoading(false);
        if (res.status) {
           loadQueue(true);
           handleClose();
        } else {
          openAlertSnackBar('error',res.message,'131px');
        }
      }).catch((err) => {
        setSendLoading(false);
        openAlertSnackBar('error','common_alert','131px')
      });
  }

  const getPdfData = useCallback(() => {
    setPdfLoader(true);
    setPDFData(null);
    const _formData = new FormData();
    _formData.append("id", JSON.stringify(lastCid));
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    loadPdf(encrData)
      .then(res => {
        setPdfLoader(false);
        if (res.status) {
          setPDFData(res.data);
        }
      });
  }, [lastCid])

  const generateAndShowPdf = useCallback(() => {
    setPdfLoader(true);
    const _formData = new FormData()
    _formData.append("id", JSON.stringify(lastCid));
    let object = createObject(_formData);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    generatePdf(encrData)
      .then(res => {
        setPdfLoader(false);
        if (res.status) {
          setPDFData(res.data);
        }
      });
  }, [lastCid])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.resetForm();
      const Obj = {
        queue_address: event.target.value
      }
      formik.setValues(Obj);
      setAddressParams((previousParams)=>({
        ...previousParams,
        search:event.target.value,
        page:1
      }));

    }
  }

  const getCheckAddressModal =()=>{
    handleOpenAddressModal();
    openCheckAddressModal();
    setSelectedRowIdAddress([]);
  }
  const openCheckAddressModal = useCallback(() => {
    setIsLoadAddress(false);
    formik.resetForm();
    const array = [searchAddress.customer, searchAddress.street + ' ' + searchAddress.housenumber, searchAddress.zip + ' ' + searchAddress.city, searchAddress.country]
    const arraySearch = array.map((value) => {
      if (!value) {
        return '';
      }
      return value;
    });
    const valueSearch = arraySearch.join(',');

    const Obj = {
      queue_address: valueSearch
    }
    formik.setValues(Obj);
    setAddressParams((previousParams)=>({
      ...previousParams,
      search:Obj.queue_address
    }))
  },[searchAddress,formik]);

  useEffect(()=>{
    if(isLoadAddress)
    {
      openCheckAddressModal();
    }
  },[isLoadAddress,openCheckAddressModal])
  const loadHistory = () => {
    sethistoryLoader(true);
    let historyForm = new FormData();
    historyForm.append('id', selectedRow.invoiceid);
    let object = createObject(historyForm);
    let encryptedToken = encryptForm(object);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    getHistory(encrData)
      .then(response => {
        if (response.status) {
          sethistory(response.data);
          sethistoryLoader(false);
        } else {
          sethistoryLoader(false);
          openAlertSnackBar('error',response.message,'131px');
        }
      }).catch((err) => {
        sethistoryLoader(false);
        openAlertSnackBar('error','common_alert','131px')
      })
  }

  const handleChangeStatus = (event) => {
    setQueueParams((previousParams)=>({
      ...previousParams,
      filter:event.target.value,
      page:1
    }))
  };

  const showCreateAddress = (event) => {
    setenableSubmit(true);
    setshowCreateAdrress(true);
    formikAddress.resetForm();
    const filteredCurrencyObject = currency.filter(
      (obj) => obj.code === searchAddress?.currency
     );
    const filteredCountryObject = country.filter(
      (obj) => obj.label === searchAddress?.country
     );

    formikAddress.setFieldValue('companyname', searchAddress?.customer);
    formikAddress.setFieldValue('street', searchAddress?.street);
    formikAddress.setFieldValue('housenumber', searchAddress?.housenumber);
    formikAddress.setFieldValue('zip', searchAddress?.zip);
    formikAddress.setFieldValue('city', searchAddress?.city);
    formikAddress.setFieldValue('countrycode', filteredCountryObject.length?filteredCountryObject[0]?.code:"");
    formikAddress.setFieldValue('currencycode',  filteredCurrencyObject.length>0?filteredCurrencyObject[0]?.code:"");
  };

  const handleBackButtonShow=()=>{
    setshowCreateAdrress(false);
    formikAddress.resetForm();
    if(selectedRowIdAddress.length>0)
    {
      setenableSubmit(false);
    }
  };

  useEffect(() => {
    if(openAddressModal)
    {
      const controller = new AbortController();
      const signal = controller.signal;
      const controller1 = new AbortController();
      const signal1 = controller1.signal;
      const controller3 = new AbortController();
      const signal3 = controller3.signal;
      const controller4 = new AbortController();
      const signal4 = controller4.signal;
      const controller5 = new AbortController();
      const signal5 = controller5.signal;
      getCurrency(signal)
        .then(currencyData => {
          if((currencyData)&&(currencyData.status)&&(currencyData.data)&&(currencyData.data.length>0))
          {
              setCurrency(currencyData.data);
          }
        });
      getLanguages(signal1)
        .then(data => {
          if((data)&&(data.status)&&(data.data)&&(data.data.length>0))
          {
              setLanguage(data.data);
          }
        });
      getConditions(signal3)
        .then(data => {
          if((data)&&(data.status)&&(data.data)&&(data.data.length>0))
          {
            setConditions(data.data);
          }
        });

      getCountry(signal4)
        .then(data => {
          if((data)&&(data.status)&&(data.data)&&(data.data.length>0))
          {
              setCountry(data.data);
          }
        });
      getTaxcode(signal5)
        .then(data => {
          if((data)&&(data.status)&&(data.data)&&(data.data.length>0))
          {
            let taxLength = data.data.reduce((max, obj) => obj.id > max ? obj.id : max, data.data[0].id) + 1;
            let empty = {id:taxLength,code:'',label:'',value:''};
            data.data.push(empty);
            let tax = data.data;
            settaxCode(tax);
          }
        });
      return () => {
        controller.abort();
        controller1.abort();
        controller3.abort();
        controller4.abort();
        controller5.abort();
      };
    }
  }, [openAddressModal]);

  const initialValuesCreateAddr = {
    companyname: "",
    street: "",
    housenumber: "",
    zip: "",
    city: "",
    countrycode: "",
    taxcode: '',
    currencycode: "",
    conditioncode: "",
    language: "",
    email:""
  };

  const handleSearchKeyDown = (event) => {
    setSearchFields(event.target.value);
    if (event.key === "Enter") {
      event.preventDefault();
      setQueueParams((previousParams)=>({
        ...previousParams,
        search:event.target.value,
        page:1
      }))
    }
  }



  const validationSchema = Yup.object().shape({
    companyname: Yup.string().required(t('address_companyname_required')),
    street: Yup.string().required(t('address_street_required')),
    housenumber: Yup.string().required(t('address_housenumber_required')),
    zip: Yup.string().required(t('address_zip_required')),
    city: Yup.string().required(t('address_city_required')),
    countrycode: Yup.string().required(t('address_countrycode_required')),
    currencycode: Yup.string().required(t('address_currencycode_required')),
    conditioncode: Yup.string().required(t('address_conditioncode_required')),
    language: Yup.string().required(t('address_language_required')),
    email: Yup.string().test(
      'isMultipleEmails',
      t('invalid_email'),
      value => {
        if (!value) return false; 
        const emails = value.split(',').map(email => email.trim());
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
        return emails.every(email => emailRegex.test(email));
      }
    )
  });

  const formikAddress = useFormik({
    initialValues: initialValuesCreateAddr,
    validationSchema,
    onSubmit: (values) => {
      setLoadingAddress(true);
      const addressForm = new FormData();
      const addressObj = initialValuesCreateAddr;
      addressObj['companyname'] = values.companyname;
      addressObj['street'] = values.street;
      addressObj['housenumber'] = values.housenumber;
      addressObj['zip'] = values.zip;
      addressObj['city'] = values.city;
      addressObj['countrycode'] = values.countrycode;
      addressObj['taxcode'] = values.taxcode;
      addressObj['currencycode'] = values.currencycode;
      addressObj['conditioncode'] = values.conditioncode;
      addressObj['language'] = values.language;
      addressObj['email'] = values.email;
      addressObj["userName"] = userDetails.username?userDetails.username:"";
      addressObj['template_json'] = "";
      // addressObj['groupcode'] = process.env.REACT_APP_GROUPCODE;
      addressForm.append('message', JSON.stringify(addressObj));
      const filteredCountryObject = country.filter(
        (obj) => obj.code === values.countrycode
       );
      const obj ={
        customer:values.companyname,
        street:values.street,
        housenumber:values.housenumber,
        zip:values.zip,
        city:values.city,
        country:filteredCountryObject[0].label
      }
      let object = createObject(addressForm);
      let encryptedToken = encryptForm(object);
      let encrData = new FormData();
      encrData.append("encrToken", encryptedToken);
      createAddress(encrData)
        .then(res => {
          setLoadingAddress(false);
          if (res.status) {
            setSearchAddress(obj);
            setIsLoadAddress(true);
            setshowCreateAdrress(false);
          }
        }).catch((err)=>{
          setLoadingAddress(false);
        });
    }
  });

  const handleFieldChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    // if ((fieldValue === 'CH') || (fieldValue === 'LI')) {
    //   formikAddress.setFieldValue('taxcode', 'SSVR77');
    // } else {
    //   formikAddress.setFieldValue('taxcode', 'TAXFREE');
    // }
    formikAddress.setFieldValue(fieldName, fieldValue);
  };

  const handleWithDifferentMail = ()=>{
    if(formikEmail.isValid )
      {
        let addressEmail = selectedRow?.addressemail?.toLowerCase();
        let invoiceEmail = selectedRow?.email?.toLowerCase();
        let updatedEmail = formikEmail.values.email_value.toLowerCase();
        if((invoiceEmail ===  addressEmail) && (addressEmail === updatedEmail)){
          sendPdfData();
        }else{
          updateEmailAndSend();
        }
      }
    // formikEmail.submitForm();
  }

  const submitAddressData = (event) => {
    formikAddress.handleSubmit();
  }

  return (
    <>
      {
        isLoading ?
          (
            <Loader />
          )
          : (
            <Grid container spacing={1} sx={{ margin: "10px 0 0 0"}}>
              <Grid item container xs={12} spacing={1} alignItems='center'>
                  <Grid item xs={12}  xl={2.5} >
                     <Typography
                          variant="h6"
                          noWrap
                          component="span"
                        >
                          {t('invoice_queue')}
                        </Typography>
                   </Grid> 
                  <Grid item container xs={12} md={12} lg={6} xl={4} spacing={1} justifyContent="flex-end" sx={{[theme.breakpoints.down('lg')]: {
                        justifyContent: 'flex-start'
                      }}} >
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                      <TextField
                        label={t("common_search")}
                        id="searchInvoices"
                        variant="outlined"
                        margin="normal"
                        size="small"
                        onKeyDown={handleSearchKeyDown}
                        fullWidth
                        sx={{m:0,width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} sm={4} md={5} lg={5}>
                      <TextField
                        id="status"
                        name="status"
                        label={t("status")}
                        variant="outlined"
                        onChange={handleChangeStatus}
                        select
                        margin="normal"
                        size="small"
                        value={queueParams.filter || ''}
                        fullWidth
                        sx={{ width: "100%",m:'0px' }}
                      >
                        <MenuItem value=''>
                          {t("status")}
                        </MenuItem>
                        {status?.map((option) => (
                          <MenuItem key={option.id} value={option.code}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={3} sm={2} >
                      <FormGroup>
                        <FormControlLabel control={<CustomCheckbox  onChange={handleCheckBoxChange}/>} label="Done" />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {(!matchDownMD)?(<Grid 
                      item 
                      container xs={12} md={12} lg={6} xl={5.5}
                      spacing={1} 
                      justifyContent='flex-end'
                      >
                        <Grid item xs={12} container justifyContent='flex-end'>
                          <Button key="AccountSettings" onClick={handleClick} title={t('account_settings')} sx={{'&:hover': {
                            background:'#8B9635'
                            }, color:theme.palette.mode === "light" ? "#fff" : "#000",background:'#8B9635',textTransform: 'none',justifyContent: 'center' }} >
                              <ListIcon />{t('check_options')}<ArrowDropDownIcon />
                          </Button>
                        </Grid>
                        <CustomMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                          }}  
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                          {(isSent) && (
                            <MenuItem disableRipple>
                            <Grid item xs={12}  >
                                <CustomBtn  loading={sendLoading} variant="contained" size="small" severity="sucess" title={t("common_send")} onClick={handleOpenSendModal}>
                                  {t('common_send')}
                                </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                          {(isReopen) && (
                            <MenuItem disableRipple>
                            <Grid item xs={12}  >
                                <CustomBtn  loading={sendLoading} variant="contained" size="small" severity="sucess" title={t("common_resend")} onClick={handleOpenSendModal}>
                                  {t('common_resend')}
                                </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                          {(isPushtoProffix) && (
                            <MenuItem disableRipple>
                              <Grid item xs={12} >
                                <CustomBtn variant="contained"  size="small" loading={loading} severity="sucess" onClick={handleOpenPushModal} title={t("push_to_proffix")} >
                                  {t('push_to_proffix')}
                                </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                          {(checkAddress) && (
                            <MenuItem disableRipple>
                              <Grid item xs={12} >
                                  <CustomBtn variant="contained"  size="small" severity="sucess" onClick={getCheckAddressModal} title={t("common_verify_address")} >
                                    {t('common_verify_address')}
                                  </CustomBtn>
                                </Grid>
                            </MenuItem>
                          )}
                          {(checkTemplate) && (
                            <MenuItem disableRipple>
                              <Grid item xs={12} >
                                  <CustomBtn variant="contained"  size="small" severity="sucess" onClick={getTemplateModal} title={t("common_verify_template")} >
                                    {t('common_verify_template')}
                                  </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                          {(isSingleSelection) && (
                            <MenuItem disableRipple>
                              <Grid item xs={12}>
                                  <CustomBtn   variant="contained" size="small" severity="sucess" title={t("common_update_email")} onClick={handleOpenEmailModal}>
                                    {t('common_update_email')}
                                  </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                          {(isUseCredit) && (
                            <MenuItem disableRipple>
                              <Grid item xs={12}>
                                  <CustomBtn   variant="contained" size="small" severity="sucess" title={t("common_add_credit")} onClick={addCreditModal}>
                                    {t('common_add_credit')}
                                  </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                          {(isDirectPayment) && (
                            <MenuItem disableRipple>
                              <Grid item xs={12}>
                                  <CustomBtn   variant="contained" size="small" severity="sucess" title={t("common_remove_credit")} onClick={removeCreditModal}>
                                    {t('common_remove_credit')}
                                  </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                          {isCreatePdf && (
                            <MenuItem disableRipple>
                              <Grid item xs={12} >
                                <CustomBtn variant="contained" size="small" loading={createPdfLoading} severity="sucess" title={t("common_generate_invoice")} onClick={createPdfData}>
                                  {t('common_generate_invoice')}
                                </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                          {(isOpen) &&<MenuItem disableRipple>
                              <Grid item xs={12} >
                                <CustomBtn variant="contained" size="small" severity="sucess" title={t("add_pages")} onClick={handleOpenAddPagesModal}>
                                  {t('add_pages')}
                                </CustomBtn>
                              </Grid>
                          </MenuItem>}
                          {(isDone) && (
                            <MenuItem disableRipple>
                            <Grid item xs={12} >
                                <CustomBtn   variant="contained" size="small" severity="sucess" title={t("status_to_done")} onClick={handleStatusDoneModal}>
                                  {t('status_to_done')}
                                </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                          {(isManualInvoice) && (
                            <MenuItem disableRipple>
                            <Grid item xs={12} >
                                <CustomBtn   variant="contained" size="small" severity="sucess" title={t("common_remove")} onClick={handleStatusDeleteModal}>
                                  {t('common_remove')}
                                </CustomBtn>
                              </Grid>
                            </MenuItem>
                          )}
                        </CustomMenu>
                    </Grid>):
                    <Grid 
                      item 
                      container xs={12} md={12} lg={6} xl={5.5}
                      spacing={1} 
                      justifyContent='flex-end'
                      sx={{[theme.breakpoints.down('md')]: {
                        justifyContent: 'flex-start'
                        }}}
                      >
                        {isCreatePdf && (
                          <Grid item xs={12} sm={4} md={2.4}  >
                            <CustomBtn variant="contained" size="small" loading={createPdfLoading} severity="sucess" title={t("common_generate_invoice")} onClick={createPdfData}>
                              {t('common_generate_invoice')}
                            </CustomBtn>
                          </Grid>
                        )}
                        {(isOpen) &&
                          <Grid item xs={12} sm={4} md={2.4}  >
                            <CustomBtn variant="contained" size="small"  severity="sucess" title={t("add_pages")} onClick={handleOpenAddPagesModal}>
                              {t('add_pages')}
                            </CustomBtn>
                          </Grid>
                        }
                        {(isSingleSelection) && (
                          <Grid item xs={12} sm={4} md={2.4}  >
                              <CustomBtn   variant="contained" size="small" severity="sucess" title={t("common_update_email")} onClick={handleOpenEmailModal}>
                                {t('common_update_email')}
                              </CustomBtn>
                            </Grid>
                        )}
                        {(isUseCredit) && (
                              <Grid item xs={12} sm={4} md={2.4}>
                                  <CustomBtn   variant="contained" size="small" severity="sucess" title={t("common_add_credit")} onClick={addCreditModal}>
                                    {t('common_add_credit')}
                                  </CustomBtn>
                              </Grid>
                          )}
                          {(isDirectPayment) && (
                              <Grid item xs={12} sm={4} md={2.4}>
                                  <CustomBtn   variant="contained" size="small" severity="sucess" title={t("common_remove_credit")} onClick={removeCreditModal}>
                                    {t('common_remove_credit')}
                                  </CustomBtn>
                              </Grid>
                          )}
                        {(isDone) && (
                          <Grid item xs={12} sm={4} md={3} >
                              <CustomBtn   variant="contained" size="small" severity="sucess" title={t("status_to_done")} onClick={handleStatusDoneModal}>
                                {t('status_to_done')}
                              </CustomBtn>
                            </Grid>
                        )}
                        {(isSent) && (
                          <Grid item xs={12} sm={4} md={1.8} >
                              <CustomBtn  loading={sendLoading} variant="contained" size="small" severity="sucess" title={t("common_send")} onClick={handleOpenSendModal}>
                                {t('common_send')}
                              </CustomBtn>
                            </Grid>
                        )}
                        {(isReopen) && (
                          <Grid item xs={12} sm={4} md={1.8} >
                              <CustomBtn  loading={sendLoading} variant="contained" size="small" severity="sucess" title={t("common_resend")} onClick={handleOpenSendModal}>
                                {t('common_resend')}
                              </CustomBtn>
                            </Grid>
                        )}
                        {(isPushtoProffix) && (
                          <Grid item xs={12} sm={4} md={2.4} >
                            <CustomBtn variant="contained"  size="small" loading={loading} severity="sucess" onClick={handleOpenPushModal} title={t("push_to_proffix")} >
                              {t('push_to_proffix')}
                            </CustomBtn>
                          </Grid>
                        )}
                        {(checkAddress) && (
                          <Grid item xs={12} sm={4} md={2.4} >
                              <CustomBtn variant="contained"  size="small" severity="sucess" onClick={getCheckAddressModal} title={t("common_verify_address")} >
                                {t('common_verify_address')}
                              </CustomBtn>
                            </Grid>
                        )}
                        {(checkTemplate) && (
                          <Grid item xs={12} sm={4} md={2.4} >
                              <CustomBtn variant="contained"  size="small" severity="sucess" onClick={getTemplateModal} title={t("common_verify_template")} >
                                {t('common_verify_template')}
                              </CustomBtn>
                          </Grid>
                        )}
                  </Grid> 
                  }
                    
                  
              </Grid>
              <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} md={6.5} style={{ minHeight: '755px'}}>
                    <CustomDataGrid
                      rows={serviceData}
                      getRowId={(row) => row.cid}
                      columns={columns}
                      pageSize={queueParams.limit}
                      onRowClick={handleRowClick}
                      onSelectionModelChange={handleSelectionModelChange}
                      selectionModel={selectedRowIds}
                      page={queueParams.page-1}
                      onSortModelChange={handleSortModelChange}
                      getRowClassName={getRowClassName}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                      loading={tableLoader}
                      rowHeight={33}
                      headerHeight={40}
                      pagination
                      paginationMode="server"
                      rowsPerPageOptions={[10, 20, 50]}
                      paginationComponent={Pagination}
                      rowCount={queueCount}
                      checkboxSelection
                      localeText={localeText}
                      scrollbarSize={0}
                      componentsProps={{
                        pagination: {
                          labelRowsPerPage: t('common_rows_per_page'),
                        }
                      }}
                      disableColumnMenu 
                      components={{
                        FilterPanel: CustomGridPanel,
                        LoadingOverlay: CustomLoadingOverlay,
                        NoRowsOverlay: CustomNoRowsOverlay
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <CustomDiv sx={{ width: '100%', flex: '1 0 43%',height: '755px'}}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <CustomTabs
                          value={value}
                          onChange={handleChange}
                          TabIndicatorProps={{
                            style:
                            {
                              backgroundColor: '#8B9635',
                            },
                          }}
                          aria-label="basic tabs example">
                          <Tab className='commonqueueTab' icon={<ListIcon />} iconPosition="start" label={t('header')} title={t('header')}  {...a11yProps(0)} />
                          <Tab className='commonqueueTab' icon={<FileCopyIcon />} iconPosition="start" label={t('items')} title={t('items')}  {...a11yProps(1)} />
                          {(isHistory) && (
                            <Tab className='commonqueueTab' icon={<HistoryIcon />} iconPosition="start" label={t('common_history')} title={t('common_history')}  {...a11yProps(1)} />)}
                          {(isPdf) && (
                            <Tab className='commonqueueTab' icon={<InsertDriveFileIcon />} iconPosition="start" label={t('common_pdf')} title={t('common_pdf')}  {...a11yProps(2)} />
                          )}
                        </CustomTabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                        {
                          Object.keys(selectedRow).length !== 0 ? (
                            Object.keys(selectedRow).map((item, index) => (
                              (item !== 'items' && item !== 'iscreditinModule' && item !== 'credit' && item !== 'ismanualinvoice' && item !== 'iswithoutpushtoproffix' && item !== 'guthaben' && item !== 'creditused' &&  item !== 'street' && item !== 'cid' && item !== 'housenumber' && item !== 'zip' && item !== 'city' && item !== 'country' && item !== 'iolibuid' && selectedRow[item]) ? <span style={{ display: 'flex' }} key={index}><span style={{ display: 'Flex', flex: '0 0 30%', padding: '10px 0px 0px 10px' }}>{t(item)}:</span><span style={{ padding: '10px 0px 0px 0px' }}>{(item === "invoice_date" || item === "senton" || item === "createddate") ? ((selectedRow[item]) && formatDate(selectedRow[item])) : (item === "status" ? ((selectedRow[item].startsWith("Sent")) ? "Sent" : selectedRow[item]) : ((item === "total_price" ? formattedAmount(parseFloat(selectedRow[item])):selectedRow[item])))}</span></span> : ''
                            ))
                          ) : (
                            <span>{t('common_no_rows_selected')}</span>
                          )
                        }
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <div style={{ height: '635px' }}>
                          <CustomDataGridItem 
                            rows={selectedItem}
                            getRowId={(row) =>  selectedItem.indexOf(row)}
                            columns={itemsColumn}
                            localeText={localeText}
                          />
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <div className='historySection'>
                          {
                            !historyLoader ?
                              (historyData.length !== 0 ?
                                historyData.map((d, index) => {
                                  return (
                                    <Timeline key={index} position="alternate">
                                      <TimelineItem>
                                        <TimelineOppositeContent color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                          {formatDate(d.date)}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot sx={{ py: '9px', px: '9px' }} />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>{(d.iswithoutpushtoproffix)&&(d.code.toLowerCase()==='done') ? 'This invoice has been Done without Proffix':d.message}
                                          <div style={{ color: '#8B9635' }}>{d.user && d.user}</div>
                                        </TimelineContent>
                                      </TimelineItem>
                                    </Timeline>
                                  )
                                })
                                : (
                                  <span>{t('common_no_history_found')}</span>
                                )) : (<Loader />)
                          }
                        </div>
                      </TabPanel>
                      <TabPanel className='pdfsection' value={value} index={3} sx={{ height: '100%' }}>
                        <div style={{ position: 'relative', height: '100%' }}>
                          {pdfData !== null ? (<iframe
                            title="PDF Viewer"
                            src={`data:application/pdf;base64,${pdfData}`}
                            width="100%"
                            height="100%"
                          ></iframe>) :
                            !pdfLoader ? (
                              <CustomCreateBtn variant="contained" size="small" severity="sucess" sx={{ position: "absolute", top: '35%', left: "45%" }} onClick={generateAndShowPdf}>
                                {t('common_generate_pdf')}
                              </CustomCreateBtn>
                            ) : (<Loader />)
                          }
                        </div>
                      </TabPanel>
                    </CustomDiv>
                  </Grid>

              </Grid>
              </Grid>

          )}
      <Modal
        open={openPushModal}
        onClose={handleClosePushModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBoxPushtoProffix>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleClosePushModal}>
              <CloseIcon sx={{ color: "#8B9635" }} />
            </IconButton>
          </div>
          <div style={{ padding: "0px 25px 20px 25px", textAlign: 'start' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t('push_to_proffix')}
            </Typography>
            <p id="child-modal-description">
              {t('confirm_push_to_proffix')}
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <CustomBtn onClick={handleClosePushModal} sx={{ mt: 2 }}>
                {t('common_cancel')}
              </CustomBtn>
              <CustomBtn onClick={sendSelectedIdsToPushQueue} sx={{ mt: 2 }}>
                {t('push_to_proffix')}
              </CustomBtn>
            </div>
          </div>
        </CustomBoxPushtoProffix>
      </Modal>

      <Modal
        open={openAddressModal}
        onClose={() => true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBoxAddress>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleCloseAddress}>
              <CloseIcon sx={{ color: "#8B9635" }} />
            </IconButton>
          </div>
          <div style={{ padding: "0px 25px 20px 25px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container>
                <span style={{ flexGrow: 1, margin: '0 0 10px 0',alignItems:'center' }} className='commonCreateLabel'>
                    { showCreateAdrress && <IconButton onClick={handleBackButtonShow}>
                      <ArrowBackIcon sx={{ color: "#8B9635" }} />
                      </IconButton>
                    }
                  {!showCreateAdrress ? t('common_verify_address'):  t('common_create_address')}
                </span>
                <Grid item xs={12} hidden={showCreateAdrress}>
                  <Grid item xs={12}>
                      <Item >
                        <div className='actionSectionRight'>
                          <div className='searchInvoiceDiv'>
                            <TextField
                              label={t("common_search")}
                              id="queue_address"
                              name='queue_address'
                              variant="outlined"
                              {...formik.getFieldProps('queue_address')}
                              value={formik.values.queue_address}
                              onChange={formik.handleChange}
                              size="small"
                              fullWidth
                              onKeyDown={handleKeyDown}
                              sx={{ m: 1, width: '100%' }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                              }}
                            />
                          </div>
                        </div>
                      </Item>
                      <div style={{ height: "300px", marginTop: '5px' }}>
                        <CustomDataGridModal
                          rows={addressData}
                          getRowId={(row) => row.cid}
                          columns={columnAddress}
                          rowHeight={33}
                          headerHeight={40}
                          pagination
                          loading={tableLoaderAddress}
                          onPageChange={addressHandlePageChange}
                          pageSize={addressParams.limit}
                          page={addressParams.page-1}
                          getRowClassName={getRowClassNameAddress}
                          onSortModelChange={addressHandleSortModelChange}
                          onPageSizeChange={addressHandlePageSizeChange}
                          onSelectionModelChange={handleSelectionModelChangeAddress}
                          selectionModel={selectedRowIdAddress}
                          rowsPerPageOptions={[10, 20]}
                          paginationMode="server"
                          paginationComponent={Pagination}
                          rowCount={addressCount}
                          disableColumnMenu
                          scrollbarSize={0}
                          components={{
                            FilterPanel: CustomGridPanel,
                            NoRowsOverlay: CustomNoRowsOverlay,
                            LoadingOverlay: CustomLoadingOverlay,
                          }}
                          componentsProps={{
                            pagination: {
                              labelRowsPerPage: t('common_rows_per_page'),
                            }
                          }}
                        />
                      </div>
                  </Grid>
                  <Grid item container xs={12} direction="row-reverse" sx={{mt:2}} spacing={1}>
                    <Grid item xs={2}>
                      <CustomBtn  disabled={enableSubmit} onClick={editAddress} >
                        {t('common_submit')}
                      </CustomBtn>
                    </Grid>
                    <Grid item xs={3}>
                      <CustomBtn type="button" onClick={showCreateAddress} >
                        {t('common_create')}
                      </CustomBtn>
                    </Grid>
                    <Grid item xs={2}>
                      <CustomBtn type="reset" onClick={handleCloseAddress}>
                        {t('common_cancel')}
                      </CustomBtn>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} hidden={!showCreateAdrress}>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        id="companyname"
                        label={t("company_name")+'*'}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ margin: '10px 0 0px 0' }}
                        {...formikAddress.getFieldProps('companyname')}
                        error={formikAddress.touched.companyname && Boolean(formikAddress.errors.companyname)}
                        helperText={formikAddress.touched.companyname && formikAddress.errors.companyname}
                      >
                      </TextField>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            id="street"
                            label={t("common_street")+'*'}
                            variant="outlined"
                            fullWidth
                            sx={{ margin: '10px 0 0px 0' }}
                            size="small"
                            {...formikAddress.getFieldProps("street")}
                            error={
                              formikAddress.touched.street && Boolean(formikAddress.errors.street)
                            }
                            helperText={formikAddress.touched.street && formikAddress.errors.street}
                          >
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            id="housenumber"
                            name="housenumber"
                            label={t("common_house_number")+'*'}
                            variant="outlined"
                            fullWidth
                            sx={{ margin: '10px 0 0px 0' }}
                            size="small"
                            {...formikAddress.getFieldProps("housenumber")}
                            error={
                              formikAddress.touched.housenumber && Boolean(formikAddress.errors.housenumber)
                            }
                            helperText={
                              formikAddress.touched.housenumber && formikAddress.errors.housenumber
                            }
                          />
                        </Grid>
                      </Grid>
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          id="zip"
                          name="zip"
                          label={t('common_zip')+'*'}
                          variant="outlined"
                          fullWidth
                          sx={{ margin: '10px 0 0px 0' }}
                          size="small"
                          {...formikAddress.getFieldProps("zip")}
                          error={
                            formikAddress.touched.zip &&
                            Boolean(formikAddress.errors.zip)
                          }
                          helperText={
                            formikAddress.touched.zip && formikAddress.errors.zip
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="city"
                          name="city"
                          label={t('common_city')+'*'}
                          variant="outlined"
                          fullWidth
                          sx={{ margin: '10px 0 0px 0' }}
                          size="small"
                          {...formikAddress.getFieldProps("city")}
                          error={
                            formikAddress.touched.city &&
                            Boolean(formikAddress.errors.city)
                          }
                          helperText={
                            formikAddress.touched.city &&
                            formikAddress.errors.city
                          }
                        />
                     </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={6}>
                      <TextField
                            id="countrycode"
                            label={t('common_country_code')+'*'}
                            variant="outlined"
                            fullWidth
                            select
                            sx={{ margin: '10px 0 0px 0' }}
                            size="small"
                            {...formikAddress.getFieldProps("countrycode")}
                            error={
                              formikAddress.touched.countrycode &&
                              Boolean(formikAddress.errors.countrycode)
                            }
                            helperText={
                              formikAddress.touched.countrycode && formikAddress.errors.countrycode
                            }
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: "265px",
                                  },
                                },
                              },
                            }}
                          >
                            {country.map((option) => (
                              <MenuItem key={option.id} value={option.code}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                            id="taxcode"
                            label={t('tax_field')}
                            variant="outlined"
                            fullWidth
                            select
                            sx={{ margin: '10px 0 0px 0' }}
                            size="small"
                            {...formikAddress.getFieldProps("taxcode")}
                            error={
                              formikAddress.touched.taxcode &&
                              Boolean(formikAddress.errors.taxcode)
                            }
                            helperText={
                              formikAddress.touched.taxcode && formikAddress.errors.taxcode
                            }
                          >
                            {taxCode.map((option) => (
                                  <MenuItem key={option.id} value={option.code}>
                                    {option.label}
                                  </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          id="conditioncode"
                          label={t('common_condition_code')+'*'}
                          variant="outlined"
                          fullWidth
                          select
                          sx={{ margin: '10px 0 0px 0' }}
                          size="small"
                          {...formikAddress.getFieldProps("conditioncode")}
                          error={
                            formikAddress.touched.conditioncode &&
                            Boolean(formikAddress.errors.conditioncode)
                          }
                          helperText={
                            formikAddress.touched.conditioncode && formikAddress.errors.conditioncode
                          }
                        >
                          {condition?.map((option) => (
                            <MenuItem key={option.id} value={option.code}>
                              {t(option.label)}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="language"
                          label={t("common_language")+'*'}
                          variant="outlined"
                          fullWidth
                          select
                          sx={{ margin: '10px 0 0px 0' }}
                          size="small"
                          {...formikAddress.getFieldProps("language")}
                          error={
                            formikAddress.touched.language &&
                            Boolean(formikAddress.errors.language)
                          }
                          helperText={
                            formikAddress.touched.language &&
                            formikAddress.errors.language
                          }
                        >
                          {lang?.map((option) => (
                            <MenuItem key={option.id} value={option.code}>
                              {option.code}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          id="currencycode"
                          label={t('common_currency_code')+'*'}
                          variant="outlined"
                          fullWidth
                          select
                          sx={{ margin: '10px 0 0px 0' }}
                          size="small"
                          {...formikAddress.getFieldProps("currencycode")}
                          error={
                            formikAddress.touched.currencycode &&
                            Boolean(formikAddress.errors.currencycode)
                          }
                          helperText={
                            formikAddress.touched.currencycode && formikAddress.errors.currencycode
                          }
                        >
                          {currency?.map((option) => (
                            <MenuItem key={option.id} value={option.code}>
                              {option.code}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                            id="email"
                            label={t('Email')+'*'}
                            variant="outlined"
                            fullWidth
                            sx={{ margin: '10px 0 0px 0' }}
                            size="small"
                            {...formikAddress.getFieldProps("email")}
                            error={
                              formikAddress.touched.email &&
                              Boolean(formikAddress.errors.email)
                            }
                            helperText={
                              formikAddress.touched.email && formikAddress.errors.email
                            }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={1} sx={{mt:1}} direction="row-reverse">
                    <Grid item xs={3}>
                      <CustomBtn type="button"  loading={loadingAddress} onClick={submitAddressData}>
                        {t('common_create_address')}
                      </CustomBtn>
                    </Grid>
                    <Grid item xs={2}>
                      <CustomBtn type="button" onClick={handleCloseAddress}  >
                        {t('common_cancel')}
                      </CustomBtn>
                    </Grid>
                  </Grid>
              </Grid>
              </Grid>
            </Box>
          </div>
        </CustomBoxAddress>
      </Modal>

      <Modal
        open={openTemplateModal}
        onClose={() => true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomBoxAddress>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <IconButton onClick={handleCloseTemplate}>
              <CloseIcon sx={{ color: "#8B9635" }} />
            </IconButton>
          </div>
          <div style={{ padding: "0px 25px 20px 25px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container  spacing={2}>
                <span style={{ flexGrow: 1, margin: '0 0 10px 0' }} className='commonCreateLabel'>{t('common_verify_template')}</span>
                  <Grid item xs={12}>
                    <Item >
                      <div className='actionSectionRight'>
                        <div className='searchInvoiceDiv'>
                          <TextField
                            label={t("common_search")}
                            id="queue_template"
                            name='queue_template'
                            variant="outlined"
                            {...formikTemplate.getFieldProps('queue_template')}
                            value={formikTemplate.values.queue_template}
                            onChange={formikTemplate.handleChange}
                            size="small"
                            fullWidth
                            onKeyDown={handleKeyDownTemplate}
                            sx={{ m: 1, width: '100%' }}
                            InputProps={{
                              endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                            }}
                          />
                        </div>
                      </div>
                    </Item>
                    <div style={{ height: "300px", marginTop: '5px' }}>
                      <CustomDataGridModal
                        rows={templateData}
                        getRowId={(row) => row.cid}
                        columns={columnTemplate}
                        rowHeight={33}
                        headerHeight={40}
                        pagination
                        page={templateParams.page-1}
                        loading={tableLoaderTemplate}
                        onPageChange={templateHandlePageChange}
                        getRowClassName={getRowClassNameTemplate}
                        pageSize={templateParams.limit}
                        onPageSizeChange={templateHandlePageSizeChange}
                        onSortModelChange={templatehandleSortModelChange}
                        onSelectionModelChange={handleSelectionModelChangeTemplate}
                        selectionModel={selectedRowIdTemplate}
                        rowsPerPageOptions={[10, 20]}
                        paginationMode="server"
                        paginationComponent={Pagination}
                        rowCount={templateCount}
                        scrollbarSize={0}
                        components={{
                          FilterPanel: CustomGridPanel,
                          NoRowsOverlay: CustomNoRowsOverlay,
                          LoadingOverlay: CustomLoadingOverlay,
                        }}
                        disableColumnMenu
                        componentsProps={{
                          pagination: {
                            labelRowsPerPage: t('common_rows_per_page'),
                          }
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item container xs={12} spacing={1} direction="row-reverse">
                    <Grid item xs={2}>
                      <CustomBtn onClick={formikTemplate.handleSubmit} disabled={enableSubmitTemplate}  >
                        {t('common_submit')}
                      </CustomBtn>
                    </Grid>
                    <Grid item xs={2}>
                      <CustomBtn type="reset" onClick={handleCloseTemplate} >
                          {t('common_cancel')}
                      </CustomBtn>
                    </Grid>
                  </Grid>
              </Grid>
            </Box>
          </div>
        </CustomBoxAddress>
      </Modal>
      {/* <CustomModal
          openModal={openSendModal} 
          onclose={handleCloseSendModal} 
          header={t('common_send')} 
          content={sendModalContent}/> */}
      <CustomModal
          openModal={openStatusDoneModal} 
          onclose={handleCloseStatusDoneModal} 
          header={t('status_to_done')} 
          content={statusDoneModalContent}
          mwidth={400} />
      <CustomModal
          openModal={openStatusDeleteModal} 
          onclose={handleCloseStatusDeleteModal} 
          header={t('common_remove')} 
          content={statusDelelteModalContent}
          mwidth={360} />
      <CustomModal
          openModal={openRemoveCreditModal} 
          onclose={handleCloseRemoveCreditModal} 
          header={t('common_remove_credit')} 
          content={RemoveCreditModalContent}
          mwidth={360} />
      <CustomModal
          openModal={openAddCreditModal} 
          onclose={handleCloseAddCreditModal} 
          header={t('common_add_credit')} 
          content={AddCreditModalContent}
          mwidth={360} />
          
          <Modal
            open={openSendModal}
            onClose={handleCloseSendModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
             <CustomBox>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleCloseSendModal}>
                  <CloseIcon sx={{ color: "#8B9635" }} />
                </IconButton>
              </div>
              <Grid container style={{ textAlign: "start", padding: "0px 25px 20px 25px" }}>
                <Grid container>
                  <FormControl>
                      <RadioGroup
                        row
                        value={sendPdfType}
                        onChange={handleChangeRadioSendPdf}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel value="PDF" control={<CustomRadio />} label={t('send_pdf')} />
                        <FormControlLabel value="OT" control={<CustomRadio />} label={t('send_opentrans')} />
                      </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid container>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t('common_send')} 
                  </Typography>
                </Grid>
                      {isEmailEquals?
                        <Grid container>
                          <Grid item xs={12}>
                            <p id="child-modal-description" style={{margin:'5px 0px 5px 0px'}}>
                              {iseBill&&<><b>{t('ebill_confirmation')}</b><br/></>}
                              {t('confirm_send_reminder') }
                            </p>
                            <TextField
                              id="email_value"
                              label={t('email')}
                              variant="outlined"
                              fullWidth
                              sx={{ margin: '10px 0 0px 0' }}
                              size="small"
                              value={formikEmail.values.email_value}
                              onChange={formikEmail.handleChange}
                              {...formikEmail.getFieldProps("email_value")}
                              error={
                                formikEmail.touched.email_value &&
                                Boolean(formikEmail.errors.email_value)
                              }
                              helperText={
                                formikEmail.touched.email_value && formikEmail.errors.email_value
                              }
                            />
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <CustomBtn onClick={handleCloseSendModal} sx={{ mt: 2 }}>
                                {t('common_cancel')}
                              </CustomBtn>
                              {/* {(!isReopen)? */}
                                {/* <CustomBtn onClick={sendPdfData} sx={{ mt: 2 }}> */}
                                <CustomBtn onClick={handleWithDifferentMail} sx={{ mt: 2 }}>
                                  { t('common_send')}
                                </CustomBtn> 
                                {/* : 
                                <CustomBtn onClick={sendIdsReopened} sx={{ mt: 2 }}>
                                  {t('common_send')} 
                                </CustomBtn>} */}
                          </div>
                          </Grid>
                        </Grid>
                        :
                        <Grid container >
                          <Grid item xs={12}>
                            <p id="child-modal-description" style={{margin:'5px 0px 10px 0px'}}>
                              {t('confirm_different_email')}
                            </p>
                            <TextField
                              id="email_value"
                              label={t('email')}
                              variant="outlined"
                              fullWidth
                              sx={{ margin: '10px 0 0px 0' }}
                              size="small"
                              value={formikEmail.values.email_value}
                              onChange={formikEmail.handleChange}
                              {...formikEmail.getFieldProps("email_value")}
                              error={
                                formikEmail.touched.email_value &&
                                Boolean(formikEmail.errors.email_value)
                              }
                              helperText={
                                formikEmail.touched.email_value && formikEmail.errors.email_value
                              }
                            />
                          </Grid>
                          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                            <Grid item xs={3}>
                              <CustomBtn onClick={handleCloseSendModal} sx={{ mt: 2 }}>
                                {t('common_cancel')}
                              </CustomBtn>
                            </Grid>
                            <Grid item xs={5}>
                              <CustomBtn  onClick={handleWithDifferentMail} sx={{ mt: 2 }}>
                                  {t('common_send')}
                              </CustomBtn> 
                            </Grid>
                          </Grid>
                        </Grid>
                      }
              </Grid>
            </CustomBox>
          </Modal>

          <Modal
            open={openEmailModal}
            onClose={handleCloseEmailModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
             <CustomBox>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleCloseEmailModal}>
                  <CloseIcon sx={{ color: "#8B9635" }} />
                </IconButton>
              </div>
              <div style={{ textAlign: "start", padding: "0px 25px 20px 25px" }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {t('common_update_email')} 
                </Typography>
                 <Grid container sx={{mt:2}}>
                    <p id="child-modal-description" style={{margin:'5px 0px 10px 0px'}}>
                      {t('confirm_different_email')}
                    </p>
                    <Grid item xs={12}>
                        <TextField
                          id="email_value"
                          label={t('email')}
                          variant="outlined"
                          fullWidth
                          sx={{ margin: '10px 0 0px 0' }}
                          size="small"
                          value={formikEmail.values.email_value}
                          onChange={formikEmail.handleChange}
                          {...formikEmail.getFieldProps("email_value")}
                          error={
                            formikEmail.touched.email_value &&
                            Boolean(formikEmail.errors.email_value)
                          }
                          helperText={
                            formikEmail.touched.email_value && formikEmail.errors.email_value
                          }
                        />
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                      <Grid item xs={3}>
                        <CustomBtn onClick={handleCloseEmailModal} sx={{ mt: 2 }}>
                          {t('common_cancel')}
                        </CustomBtn>
                      </Grid>
                      <Grid item xs={5}>
                        <CustomBtn  onClick={handleEmailChange} sx={{ mt: 2 }}>
                            {t('confirm_email')}
                        </CustomBtn> 
                      </Grid>
                    </Grid>
                 </Grid>
              </div>
            </CustomBox>
          </Modal>

          <Modal
            open={openFileMergeModal}
            onClose={handleCloseFileMergeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
             <CustomBox>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <IconButton onClick={handleCloseFileMergeModal}>
                  <CloseIcon sx={{ color: "#8B9635" }} />
                </IconButton>
              </div>
              <div style={{ textAlign: "start", padding: "0px 25px 20px 25px" }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {t('add_pages')} 
                </Typography>
                 <Grid container sx={{mt:2}}>
                 <p id="child-modal-description" style={{margin:'5px 0px 10px 0px'}}>
                      {t('confirm_add_pages')}
                    </p>
                    <CustomFileBox
                        variant="contained"
                        component="label"
                        sx={{ mt: 2 }}
                      >
                        {formikPDF.values.file ? 
                          <div style={{display:'flex',alignItems:'center'}}>
                            <div title={formikPDF.values.file.name}>
                              {formikPDF.values.file.name.length> 30 ?  formikPDF.values.file.name.substring(0, 30)+'....':formikPDF.values.file.name}
                            </div> <CloseIcon sx={{ color: "#FF0000" }}  onClick={removeFile}/> 
                          </div>
                          : <div style={{display:'flex',alignItems:'center'}}>
                              <PlayArrowIcon sx={{color:'#FF0000'}}/><p>{t('common_select_PDF')}</p>
                            </div>}
                        <input
                          type="file"
                          // {...formikPDF.getFieldProps("file")}
                          hidden
                          // onChange={handleFileChange}
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            if (file) {
                              if (file.type === 'application/pdf' || file.name.endsWith('.pdf')) {
                                 formikPDF.setFieldValue('file', file);
                              }
                            }
                          }}
                        />
                      </CustomFileBox>
                    <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                      <Grid item xs={3}>
                        <CustomBtn onClick={handleCloseFileMergeModal} sx={{ mt: 2 }}>
                          {t('common_cancel')}
                        </CustomBtn>
                      </Grid>
                      <Grid item xs={5}>
                      <CustomBtn  onClick={formikPDF.submitForm} sx={{ mt: 2 }}>
                            {t('common_submit')}
                        </CustomBtn> 
                      </Grid>
                    </Grid>
                 </Grid>
              </div>
            </CustomBox>
          </Modal>

    </>
  )
}

export default Queue